<template class>
  <div ref="self" class="inspection-result">
    <v-toolbar flat>
      <v-toolbar-title class="title" v-if="isTitle">
        {{ pageTitle }}
      </v-toolbar-title>
      <div v-if="isGuidanceMode" class="mx-5">
        <InputDateMonthPicker
          name="lead_approved_month"
          :editable="editable"
          :values="getInput"
          @onInput="onChangeSearchParams"
        />
      </div>
      <div v-if="!isGuidanceMode && !isReportMonth" class="mx-5">
        <InputDatepickerCustom
          name="inspection_date_custom"
          :editable="editable"
          :values="getInput"
          @onInput="onChangeSearchParams"
        />
      </div>
      <div v-if="isReportMonth" class="mx-5">
        <InputDateMonthRangePicker
          name="inspection_month_picker"
          :editable="editable"
          :values="getInput"
          @onInput="onChangeSearchParams"
        />
      </div>
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2 mr-5"
        :disabled="multiSelectStatus"
        icon
        @click="openPrint"
      >
        <v-icon class="icon_large"> mdi-printer </v-icon>
      </v-btn>
      <v-btn
        v-if="!isGuidanceMode && !isTabDate2"
        class="ma-2 mr-5"
        color="warning"
        :disabled="multiSelectStatus || this.isDisableBtnEditAprrove"
        @click="$emit('onReject')"
      >
        承認解除
      </v-btn>
      <v-btn
        v-if="!isGuidanceMode && !isTabDate2"
        class="ma-2 mr-5"
        color="primary"
        :disabled="multiSelectStatus || this.isDisableBtnEditAprrove"
        @click="$emit('onApprove')"
      >
        承認
      </v-btn>
      <v-btn
        v-if="!isGuidanceMode && !isTabDate2"
        :disabled="disableBtnAll || this.isDisableBtnEditAprrove"
        class="ma-2"
        color="primary"
        @click="$emit('onApproveAll')"
      >
        全件承認
      </v-btn>
      <v-btn
        v-if="(isTabDate2 || isGuidanceMode) && isLoginUserMain"
        class="ma-2"
        color="primary"
        :disabled="disableBtnAll"
        @click="$emit('onGuidanceAll')"
      >
        全件指導
      </v-btn>
      <v-btn
        v-if="isGuidanceMode && isLoginUserMain"
        class="ma-2"
        color="primary"
        @click="$emit('onOpenCustomBusinessForm')"
      >
        カスタム帳票
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
// Common and functions
import { Store } from "@/store/Store.js";
import { format } from "date-fns";
import { COMPANY_TYPE } from "@/constants/COMMON";
import {
  FLAG_RELATE_FIELD,
  FLAG_MACHINE_FIELD_MANAGER,
} from "@/constants/INSPECTION";

// Components
import InputDateMonthPicker from "@/components/forms/elements/InputDateMonthPicker";
import InputDatepickerCustom from "@/components/forms/elements/InputDatepickerCustom";
import InputDateMonthRangePicker from "@/components/forms/elements/InputDateMonthRangePicker";

export default {
  data: () => {
    return {
      FLAG_RELATE_FIELD,
      FLAG_MACHINE_FIELD_MANAGER,
      searchInputs: null,
    };
  },
  components: {
    InputDateMonthPicker,
    InputDatepickerCustom,
    InputDateMonthRangePicker,
  },
  props: {
    pageTitle: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    multiSelectStatus: {
      type: Boolean,
      default: false,
    },
    updateHeader: {
      type: Object,
    },
    isTitle: {
      type: Boolean,
      default: true,
    },
    searchParams: {
      type: Object,
      defauly: null,
    },
    disableBtnAll: {
      type: Boolean,
      default: true,
    },
    isRelateField: {
      type: Number,
      default: FLAG_RELATE_FIELD.NOT_RELATE_FIELD,
    },
    isMachineFieldManager: {
      type: Number,
      default: FLAG_MACHINE_FIELD_MANAGER.NOT_FLAG,
    },
  },
  computed: {
    currentSite() {
      return Store.state.GlobalHeader.selectedSite;
    },
    isGuidanceMode() {
      return this.searchParams?.inspection_frequency == 2 ? true : false;
    },
    isReportDay() {
      return this.searchParams?.inspection_frequency == 0 ? true : false;
    },
    isTabDate2() {
      return this.searchParams?.inspection_frequency === 3 ? true : false;
    },
    isReportMonth() {
      return this.searchParams?.inspection_frequency === 1 ? true : false;
    },
    USER_LOGIN() {
      return Store.state.Login.user;
    },
    isLoginUserMain() {
      return this.USER_LOGIN.company_type == COMPANY_TYPE.OWNER;
    },
    isDisableBtnEditAprrove() {
      if (this.isReportDay) {
        return this.isRelateField == this.FLAG_RELATE_FIELD.NOT_RELATE_FIELD;
      } else {
        return (
          this.isMachineFieldManager == this.FLAG_MACHINE_FIELD_MANAGER.NOT_FLAG
        );
      }
    },
    getInput() {
      this.searchInputs = { ...this.searchParams };
      if (this.currentSite?.end_use_flg) {
        let searchParams = { ...this.searchInputs };
        let updatedDate = new Date(this.currentSite?.updated_at);
        searchParams.inspection_date_from = format(updatedDate, "yyyy/MM/01");
        searchParams.inspection_date_to = format(
          new Date(updatedDate.getFullYear(), updatedDate.getMonth() + 1, 0),
          "yyyy/MM/dd"
        );
        searchParams.lead_approved_month = format(updatedDate, "yyyy/MM/01");
        this.searchInputs = searchParams;
      }
      return this.searchInputs;
    },
  },
  mounted() {
    setTimeout(() => {
      this.updateHeaderHeight();
    }, 200);
  },
  methods: {
    onChangeSearchParams({ name, value }) {
      this.$emit("onInput", { name, value });
    },
    updateHeaderHeight() {
      const height = this.$refs.self.getBoundingClientRect().height;
      this.updateHeader.setHeaderHeight(height);
    },
    openPrint() {
      this.$emit("openPrint");
    },
  },
};
</script>
