<template>
  <div ref="self" :class="className">
    <v-card class="whole">
      <v-card-title ref="FormHeader">
        <slot name="header"></slot>
      </v-card-title>
      <v-card-text
        class="main"
        :style="{ 'max-height': params.mainHeight + 'px' }"
      >
        <slot name="main" :params="params"></slot>
      </v-card-text>
      <v-card-actions
        ref="FormFooter"
        class="footer"
        :style="{ 'min-height': params.footerHeight + 'px' }"
      >
        <slot name="footer" :params="params"></slot>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { COLORS } from "@/constants/COMMON";

export default {
  data: () => {
    return {
      params: {
        headerHeight: 10,
        mainHeight: 10,
        footerHeight: 60,
      },
    };
  },
  props: {
    className: {
      type: String,
      default: "form",
    },
    editable: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 90,
    },
    right: {
      type: Number,
      default: 5,
    },
  },
  mounted() {
    this.setPosition();
    this.setBackground();
    this.$nextTick(() => {
      setTimeout(() => {
        this.getWindowSize();
      }, 500);
    });
    window.addEventListener("resize", () => {
      this.getWindowSize();
    });
  },
  methods: {
    setPosition() {
      let el = document.querySelector(`.${this.className}`);
      el.classList.add("dynamic-dialog");
      el.style.cssText = `position: fixed; top: 5%; height: 90%; z-index: 2; right: ${this.right}%; width: ${this.width}%`;
    },
    setBackground() {
      let card = document
        .querySelector(`.dynamic-dialog`)
        .querySelector(`.v-card`);
      let window = document
        .querySelector(`.dynamic-dialog`)
        .querySelector(`.v-window`);
      if (card && window) {
        if (this.editable) {
          card.style.cssText = `background-color: ${COLORS.BG_COLOR}`;
          window.style.cssText = `background-color: ${COLORS.BG_COLOR}`;
        } else {
          card.style.cssText = `background-color: ${COLORS.PRIMARY_COLOR}`;
          window.style.cssText = `background-color: ${COLORS.PRIMARY_COLOR}`;
        }
      }
    },
    getWindowSize() {
      const self = this.$refs.self;
      const header = this.$refs.FormHeader;
      const footer = this.$refs.FormFooter;
      if (self) {
        //全体のサイズを取得
        const selfRect = self.getBoundingClientRect();
        const headerRect = header.getBoundingClientRect();
        const footerRect = footer.getBoundingClientRect();
        //params全体を更新
        let _params = { ...this.params };
        //ヘッダーの高さを引いてMainを算出
        _params.headerHeight = headerRect.height;
        _params.mainHeight =
          selfRect.height - headerRect.height - footerRect.height;
        this.params = _params;
      } else {
        let _params = { ...this.params };
        _params.mainHeight = window.innerHeight * 0.9;
        this.params = _params;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.whole {
  display: flex;
  flex-direction: column;
}
.main {
  flex: 1;
  overflow-y: auto;
  padding: 0;
}
.footer {
  justify-content: center;
  border-top: solid;
  border-color: $border_color;
  border-width: 1px;
  padding: 0;
}
</style>
