<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader v-if="!currentSite.end_use_flg" />
        <EndUseSite v-else />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <div>
              <InspectionResultHeader
                ref="tableHeader"
                :pageTitle="PAGE_TITLE"
                :multiSelectStatus="disableApproveBtn"
                :updateHeader="updateHeader"
                :searchParams="searchParams"
                :disableBtnAll="disableBtnAll"
                :isRelateField="isRelateField"
                :isMachineFieldManager="isMachineFieldManager"
                @onReject="rejectConfirm"
                @onApprove="approveConfirm"
                @onApproveAll="approveAllConfirm"
                @onGuidanceAll="guidenceAllConfirm"
                @onInput="onChangeSearchParams"
                @openPrint="openPrint"
                @onOpenCustomBusinessForm="onOpenCustomBusinessForm"
              >
              </InspectionResultHeader>
              <SearchFormWrapper v-if="!isGuidanceMode">
                <InputText
                  class="search width-small"
                  placeholder="機械名"
                  name="middle_classification"
                  :editable="true"
                  :values="searchInputs"
                  @onInput="onChangeSearchInputs"
                />
                <SelectWithFilter
                  v-if="showSelectCompany"
                  class="search width-middle"
                  placeholder="所有会社"
                  name="company_id"
                  :editable="true"
                  :values="searchInputs"
                  :items="COMPANY_ITEM"
                  @onInput="onChangeSearchInputs"
                />
                <SelectWithFilter
                  v-if="showSelectCompany"
                  class="search width-middle"
                  placeholder="点検会社"
                  name="company_inspector"
                  :editable="isContractorTabDate ? false : true"
                  :values="searchInputs"
                  :items="COMPANY_INSPECTOR"
                  @onInput="onChangeSearchInputs"
                />
                <InputText
                  class="search width-small"
                  placeholder="管理名（呼名）"
                  name="machine_field_name"
                  :editable="true"
                  :values="searchInputs"
                  @onInput="onChangeSearchInputs"
                />
                <SwitchInput
                  class="search"
                  v-if="!isTabDate2"
                  name="approved_status"
                  label="未承認のみ表示"
                  :values="searchInputs"
                  :editable="true"
                  @onInput="onChangeSearchInputs"
                />
                <SwitchInput
                  class="search"
                  v-if="isTabDate2"
                  name="leading_status"
                  label="未指導のみ表示"
                  :values="searchInputs"
                  :editable="true"
                  @onInput="onChangeSearchInputs"
                />
                <SwitchInput
                  class="search"
                  name="deficiencies_confirm_status"
                  label="不備・確認中のみ表示"
                  :values="searchInputs"
                  :editable="true"
                  @onInput="onChangeSearchInputs"
                />
                <v-btn color="primary" @click="onSearch(true)"> 検索 </v-btn>
              </SearchFormWrapper>
              <SearchFormWrapper v-if="isGuidanceMode">
                <SelectWithFilter
                  class="search width-middle"
                  placeholder="所有会社"
                  name="company_id"
                  :editable="!COMPANY_INSPECTOR_ID"
                  item_text="name"
                  item_value="id"
                  :values="searchLeadApprovedInputs"
                  :items="COMPANY_BY_FIELDS"
                  @onInput="onChangeSearchLeadApprovedInputs"
                />
                <SwitchInput
                  class="search"
                  name="lead_approved_flag"
                  label="未指導"
                  :values="searchLeadApprovedInputs"
                  :editable="true"
                  @onInput="onChangeSearchLeadApprovedInputs"
                />
                <v-btn color="primary" @click="onSearch(false)"> 検索 </v-btn>
              </SearchFormWrapper>
              <TableTab
                :items="tabItems"
                name="idx"
                :values="searchParams"
                @onInput="onChangeTableTab"
              >
                <TableSortWrapper>
                  <TableSort
                    :values="searchParams"
                    :sort_items="SORT_ITEMS"
                    sort_item_text="name"
                    sort_item_value="id"
                    :page_counts_options="PAGE_COUNT_OPTIONS"
                    :sort_order_options="TABLE_SORT_ORDERS"
                    :total_item="searchParams.total_item"
                    @onInput="onChangeSortParams"
                  />
                </TableSortWrapper>
              </TableTab>
            </div>
          </template>
          <template #tableBody="{ tableHeight }">
            <v-data-table
              v-if="!isGuidanceMode"
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              sort-by="updatedAt"
              :show-select="true"
              noDataText="データがありません。"
              item-key="key"
              @click:row="openItemForm"
            >
              <template v-slot:[`item.unapproved_count`]="{ item }">
                <div>
                  {{ `${item.unapproved_count}(${item.inspection_count})` }}
                </div>
              </template>
              <template v-slot:[`item.not_leaded_count`]="{ item }">
                <div>
                  {{ `${item.not_leaded_count}(${item.inspection_count})` }}
                </div>
              </template>
              <template v-slot:[`item.flag_error`]="{ item }">
                <div v-if="!isMonthlyMode">
                  <div v-if="item.flag_error == 1" style="color: #b00020">
                    不備
                  </div>
                  <div v-if="item.flag_error == 2">
                    <span style="color: #b00020"> 不備 </span>
                    &nbsp;
                    <span style="color: #ff7b52"> 是正済み </span>
                  </div>
                  <div v-if="item.flag_error == 3">
                    <span style="color: #b00020"> 不備 </span>
                    &nbsp;
                    <span style="color: #1b9c4f"> 確認済み </span>
                  </div>
                  <div v-if="item.flag_error == 4">
                    <span style="color: #b00020"> 不備 </span>
                    &nbsp;
                    <span style="color: #ff7b52"> 是正済み </span>
                    &nbsp;
                    <span style="color: #1b9c4f"> 確認済み </span>
                  </div>
                </div>
                <div v-if="isMonthlyMode"></div>
              </template>
            </v-data-table>
            <v-data-table
              v-if="isGuidanceMode"
              v-model="selectedLeadApproved"
              :headers="TABLE_LABELS"
              :items="LIST_LEAD_APPROVED"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              show-select
              noDataText="データがありません。"
              item-key="company_id"
            >
              <template v-slot:[`item.guidance_status`]="{ item }">
                <div>
                  {{ item.lead_approved_month ? "指導済" : "ー" }}
                </div>
              </template>
              <template v-slot:[`item.lead_approved_at`]="{ item }">
                <div>
                  {{ item.lead_approved_at ? item.lead_approved_at : "ー" }}
                </div>
              </template>
              <template v-slot:[`item.preview_guild_dance`]="{ item }">
                <v-btn
                  color="primary"
                  item-key="{item.key}"
                  @click="openDialogTemplate('prevGuildDance', item.company_id)"
                >
                  プレビュー
                </v-btn>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
    <Popup width="1600px" :dialog="popups.isShowInspecForm1">
      <InspectionForm1
        :isDialogComfirm="isDialogComfirm"
        :item="editedItem"
        :isReportDay="isReportDay"
        :isRelateField="isRelateField"
        :isMachineFieldManager="isMachineFieldManager"
        :inspection_frequency="apiParams.inspection_frequency"
        :company_type="this.loginUser.company_type"
        @cancel="closeItemForm"
        @getItems="getItems"
      />
    </Popup>
    <Popup width="500px" :dialog="popups.isShowDialog">
      <ConfirmDialog
        @close="closeDialog"
        @yes="actionDialog"
        :isUnlock="true"
        :title="popups.titleDialog"
        :text="popups.messageDialog"
      />
    </Popup>
    <Popup width="500px" :dialog="popups.isShowGuidanceDialog">
      <ConfirmDialog
        @close="closeDialog"
        @yes="actionDialog"
        :isGuidance="true"
        :title="popups.titleDialog"
        :text="popups.messageDialog"
      />
    </Popup>
    <Popup width="500px" :dialog="popups.isShowApprovalDialog">
      <ConfirmDialog
        @close="closeDialog"
        @yes="actionDialog"
        :isApproval="true"
        :title="popups.titleDialog"
        :text="popups.messageDialog"
      />
    </Popup>
    <Popup width="500px" :dialog="popups.isShowPrintDialog">
      <ConfirmDialog
        @close="closePrintDialog"
        @yes="print"
        :isExport="true"
        title="点検表出力"
      />
    </Popup>
    <Popup width="640px" :dialog="popups.isShowNoticeDialog">
      <NotificationDialog
        @close="closeNoticeDialog"
        :date="popups.maintainDate"
        :time="popups.maintainTime"
        :message="popups.messageDialog"
      />
    </Popup>
    <Popup width="500px" :dialog="popups.isShowUpdateErrorDialog">
      <ConfirmDialog
        @close="closeDialogUpdateError"
        :hasDetails="false"
        :isTwoButton="false"
        :isCenter="false"
        title="承認不可"
        text="自社が点検会社に設定されていない、点検実績も含まれています。"
      />
    </Popup>
    <Popup
      width="302mm"
      :dialog="popups.isShowDialogTemplateGuildDance"
      class="dialog-template"
    >
      <v-card>
        <v-card-title class="preview-header">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-5"
            style="background-color: #1b9c4f; color: white"
            :disabled="!popups.isChanged1 && !popups.isChanged2"
            @click="updateSafeSign"
          >
            安全部承認
          </v-btn>
          <v-btn
            v-if="isOwner"
            class="mx-5"
            color="primary"
            :disabled="popups.companyId == null"
            @click="guidenceConfirm"
          >
            指導
          </v-btn>
          <v-btn icon @click="closeDialogTemplate()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <TemplateGuildDance
          :listInspectionApprovalStatus="listInspectionApprovalStatus"
          @updateSafeSignId="updateSafeSignId"
        ></TemplateGuildDance>
      </v-card>
    </Popup>
    <Popup :dialog="popups.isShowCustomBusinessForm">
      <CustomBusinessForm
        :lead_approved_month="searchParams.lead_approved_month"
        @close="popups.isShowCustomBusinessForm = false"
      />
    </Popup>
    <Loader :isLoading="isLoading"></Loader>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import _ from "lodash";
import axios from "axios";
import { format } from "date-fns";
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS } from "@/constants/COMMON";
import {
  INSPECTIONS_TABLE_LABELS,
  ATTRIBUTE_DIALOG_ARRRAY,
  INSPECTION_RESULT_CATEGORY,
  INSPECTION_GUIDANCE_LABELS,
  CONTRACTOR_INSPECTIONS_TABLE_LABELS_DATE2,
  SORT_ITEMS,
} from "@/constants/INSPECTION";
import { COMPANY_TYPE } from "@/constants/COMMON";
import { HEADER_MENU_ITEMS_FIELD } from "@/constants/GLOBALHEADER";
import TemplateGuildDance from "./templates/TemplateGuildDance";

// Components
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import Popup from "@/components/common/Popup";
import Pagination from "@/components/forms/elements/Pagination";
import SearchFormWrapper from "@/components/forms/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/forms/elements/TableSortWrapper";
import TableSort from "@/components/forms/elements/TableSort";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import EndUseSite from "@/components/globalHeader/EndUseSite";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import NotificationDialog from "@/components/dialog/NotificationDialog";
import InputText from "@/components/forms/elements/InputText";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter";
import Label from "@/components/forms/elements/Label";
import Loader from "@/components/forms/elements/Loader";
import InspectionResultHeader from "@/views/fields/inspections/components/InspectionResultHeader";
import TableTab from "@/components/forms/elements/TableTab";
import InspectionForm1 from "@/components/forms/inspections/InspectionForm1";
import CustomBusinessForm from "@/components/forms/inspections/CustomBusinessForm";
import SwitchInput from "@/components/forms/elements/SwitchInput";

let DEFAULT_INSPECTION_RESULT_TABLE_TAB_ITEMS = {
  TAB_DATE: { id: 0, name: "作業前点検", idx: 0 },
  TAB_MONTH: { id: 1, name: "月例点検", idx: 1 },
  TAB_GUIDANCE: { id: 2, name: "点検指導実績一覧", idx: 2 },
};

let CONTRACTOR_INSPECTION_RESULT_TABLE_TAB_ITEMS = {
  TAB_DATE: { id: 0, name: "作業前点検(自社)", idx: 0 },
  TAB_DATE2: { id: 3, name: "作業前点検(他社)", idx: 1 },
  TAB_MONTH: { id: 1, name: "月例点検", idx: 2 },
  TAB_GUIDANCE: { id: 2, name: "点検指導実績一覧", idx: 3 },
};

let INSPECTION_RESULT_TABLE_TAB_ITEMS =
  DEFAULT_INSPECTION_RESULT_TABLE_TAB_ITEMS;

const PAGE_TITLE = "点検実績";

const PAGE_COUNT = 25;

const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

const STORE = "Inspections";

let TABLE_LABELS = INSPECTIONS_TABLE_LABELS;

let INITIAL_ITEM_SEARCH_PARAMS = {
  inspection_date_from: format(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    "yyyy/MM/dd"
  ),
  inspection_date_to: format(new Date(), "yyyy/MM/dd"),
  lead_approved_month: format(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    "yyyy/MM/dd"
  ),
  inspection_frequency: INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_DATE.id,
  idx: INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_DATE.idx,
  company_id: null,
  company_inspector: null,
  pageCount: PAGE_COUNT,
  currentPage: 1,
  totalPage: 1,
};

const INITIAL_ITEM_SEARCH_INPUTS = {
  approved_status: null,
  middle_classification: null,
  company_id: null,
  company_inspector: null,
  deficiencies_confirm_status: 0,
  leading_status: null,
};

const INITIAL_ITEM_LEAD_APPROVED = {
  company_id: null,
  lead_approved_flag: null,
};

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: PAGE_TITLE };
    },
  },
  components: {
    DefaultLayout,
    TableLayout,
    Pagination,
    SearchFormWrapper,
    SitePortalHeader,
    EndUseSite,
    Popup,
    InputText,
    SelectWithFilter,
    Label,
    Loader,
    InspectionResultHeader,
    TableTab,
    ConfirmDialog,
    InspectionForm1,
    CustomBusinessForm,
    NotificationDialog,
    SwitchInput,
    TemplateGuildDance,
    TableSortWrapper,
    TableSort,
  },
  data() {
    return {
      PAGE_TITLE,
      TABLE_SORT_ORDERS,
      TABLE_LABELS,
      PAGE_COUNT_OPTIONS,
      ATTRIBUTE_DIALOG_ARRRAY,
      INSPECTION_GUIDANCE_LABELS,
      INSPECTIONS_TABLE_LABELS,
      CONTRACTOR_INSPECTIONS_TABLE_LABELS_DATE2,
      SORT_ITEMS,
      items: [],
      selectedItems: [],
      selectedLeadApproved: [],
      isNewItem: false,
      editedItem: {},
      COMPANY_ITEM: [],
      COMPANY_INSPECTOR: [],
      COMPANY_BY_FIELDS: [],
      LIST_LEAD_APPROVED: [],
      isRelateField: null,
      isMachineFieldManager: null,
      isDialogComfirm: true,
      isLoading: false,
      // search param
      searchParams: { ...INITIAL_ITEM_SEARCH_PARAMS },
      NOTIFICATION: "",

      popups: {
        isShowInspecForm1: false,
        isShowPrintDialog: false,
        isShowNoticeDialog: false,
        isShowDialogTemplateGuildDance: false,
        isShowDialog: false,
        isShowApprovalDialog: false,
        isShowGuidanceDialog: false,
        isShowCustomBusinessForm: false,
        isShowUpdateErrorDialog: false,
        titleDialog: "",
        messageDialog: "",
        nameAction: "",
        maintainDate: "",
        maintainTime: "",
        companyId: null,
        safeSignId1: null,
        safeSignId2: null,
        isChanged1: false,
        isChanged2: false,
      },
      listInspectionApprovalStatus: [],
      searchInputs: { ...INITIAL_ITEM_SEARCH_INPUTS },
      searchLeadApprovedInputs: { ...INITIAL_ITEM_LEAD_APPROVED },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  async mounted() {
    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_FIELD.INSPECTOR_FIELD.id,
    });

    this.getListCompany();
    this.getListCompanyByField();
    this.getNotification();

    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
        Store.getters[`${STORE}/getFlagRelateField`],
        Store.getters[`${STORE}/getFlagMachineFieldManager`],
        Store.getters[`${STORE}/getInspectionFrequency`],
      ],
      (data) => {
        data[0].forEach((element) => {
          element.key = element.ids.toString();
        });
        this.items = data[0];
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
        this.isRelateField = data[2];
        this.isMachineFieldManager = data[3];
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [
        Store.getters[`FieldUser/getDataLeadApproved`],
        Store.getters[`FieldUser/getPaginationLeadApproved`],
      ],
      (data) => {
        this.LIST_LEAD_APPROVED = data[0];
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getListCompanyByField"],
      (data) => {
        this.COMPANY_INSPECTOR = [...data];
        this.COMPANY_INSPECTOR.unshift({
          id: null,
          name: "",
        });
        if (
          this.loginUser.company_type === 0 &&
          this.searchParams?.inspection_frequency === 3
        ) {
          for (var i = 0; i < this.COMPANY_INSPECTOR.length; i++) {
            if (this.COMPANY_INSPECTOR[i].id === this.loginUser.company_id) {
              this.COMPANY_INSPECTOR.splice(i, 1);
              break;
            }
          }
        }
        if (
          this.loginUser.company_type != COMPANY_TYPE.OWNER ||
          this.loginUser.company_type != COMPANY_TYPE.ADMIN
        ) {
          this.searchLeadApprovedInputs["company_id"] =
            this.loginUser.company_id;
        }
        this.COMPANY_BY_FIELDS = [...data];
        this.COMPANY_BY_FIELDS.unshift({
          id: null,
          name: "",
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.currentSite,
      (newValue, oldValue) => {
        if (newValue?.field_id == oldValue?.field_id) return;
        this.getListCompanyByField();
        this.getCompanyByFields();
        if (newValue.end_use_flg) {
          let searchParams = { ...this.searchParams };
          let updatedDate = new Date(newValue.updated_at);
          searchParams.inspection_date_from = format(updatedDate, "yyyy/MM/01");
          searchParams.inspection_date_to = format(
            new Date(updatedDate.getFullYear(), updatedDate.getMonth() + 1, 0),
            "yyyy/MM/dd"
          );
          searchParams.lead_approved_month = format(updatedDate, "yyyy/MM/01");
          this.searchParams = searchParams;
        }
        this.onChangeTableTab(this.searchParams.inspection_frequency);
      },
      {
        immediate: true,
        deep: true,
      }
    );

    if (this.$route.params.tab != null) {
      let now = new Date();
      this.searchParams.inspection_date_from = format(now, "yyyy/MM/dd");
      this.searchParams.inspection_date_to = format(now, "yyyy/MM/dd");
      this.onChangeTableTab(this.$route.params.tab);
    }
  },

  computed: {
    currentSite() {
      return Store.state.GlobalHeader.selectedSite;
    },

    disableApproveBtn() {
      return (
        (this.selectedItems.length === 0 &&
          this.selectedLeadApproved.length === 0) ||
        this.currentSite.end_use_flg === 1
      );
    },
    disableBtnAll() {
      return this.items.length === 0 || this.currentSite.end_use_flg === 1;
    },
    COMPANY_INSPECTOR_ID() {
      return this.loginUser.company_type != COMPANY_TYPE.OWNER &&
        this.loginUser.company_type != COMPANY_TYPE.ADMIN
        ? this.loginUser.company_id
        : null;
    },

    apiParams() {
      return {
        field_id: this.currentSite.field_id,
        inspection_date_from: this.searchParams.inspection_date_from,
        inspection_date_to: this.searchParams.inspection_date_to,
        company_id: this.searchParams.company_id,
        company_inspector: this.searchParams.company_inspector,
        machine_field_name: this.searchParams.machine_field_name,
        middle_classification: this.searchParams.middle_classification,
        inspection_frequency: this.searchParams.inspection_frequency,
        approved_status:
          this.searchParams.approved_status == 0
            ? null
            : this.searchParams.approved_status,
        deficiencies_confirm_status:
          this.searchParams.deficiencies_confirm_status,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        leading_status: this.searchParams.leading_status,
      };
    },
    apiGetListLeadApprovedParams() {
      return {
        field_id: this.currentSite.field_id,
        lead_approved_month: this.searchParams.lead_approved_month,
        company_id: this.COMPANY_INSPECTOR_ID
          ? this.COMPANY_INSPECTOR_ID
          : this.searchParams.company_id,
        lead_approved_flag:
          this.searchParams.lead_approved_flag == 0 ||
          this.searchParams.lead_approved_flag == null
            ? null
            : 0,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },

    tabItems() {
      this.checkCompanyType();
      return Object.keys(INSPECTION_RESULT_TABLE_TAB_ITEMS).map((key) => {
        return INSPECTION_RESULT_TABLE_TAB_ITEMS[key];
      });
    },

    loginUser() {
      return Store.state.Login.user;
    },

    isOwner() {
      return this.listInspectionApprovalStatus[0]?.company_type != 0;
    },

    isContractorTabDate() {
      if (
        this.loginUser.company_type === 0 &&
        this.searchParams?.inspection_frequency === 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    isGuidanceMode() {
      return this.searchParams?.inspection_frequency ==
        INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_GUIDANCE.id
        ? true
        : false;
    },
    isMonthlyMode() {
      return this.searchParams?.inspection_frequency ==
        INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_MONTH.id
        ? true
        : false;
    },

    /**
     * Show select search if role is owner or admin
     */
    showSelectCompany() {
      if (
        this.loginUser?.company_type === COMPANY_TYPE.PATNER ||
        this.loginUser?.company_type === COMPANY_TYPE.RENTAL
      )
        return false;
      return true;
    },

    isReportDay() {
      return this.searchParams?.inspection_frequency === 0;
    },
    isTabDate2() {
      return this.searchParams?.inspection_frequency === 3;
    },
  },

  methods: {
    formUpdate(params) {
      this.editedItem = { ...params };
    },
    checkCompanyType() {
      if (this.loginUser.company_type === 0) {
        INSPECTION_RESULT_TABLE_TAB_ITEMS =
          CONTRACTOR_INSPECTION_RESULT_TABLE_TAB_ITEMS;
        if (this.searchParams?.inspection_frequency === 1) {
          this.TABLE_LABELS = this.INSPECTIONS_TABLE_LABELS;
        }
      } else {
        INSPECTION_RESULT_TABLE_TAB_ITEMS =
          DEFAULT_INSPECTION_RESULT_TABLE_TAB_ITEMS;
        this.TABLE_LABELS = INSPECTIONS_TABLE_LABELS;
      }
    },
    /**
     * (Common)
     * pageUpdate
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      if (
        this.searchParams.inspection_frequency ==
        INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_GUIDANCE.id
      ) {
        this.getListLeadApproved();
      } else if (this.searchParams.inspection_frequency === 3) {
        this.getOtherItems();
      } else {
        this.getItems();
      }
    },

    /**
     * (Common)
     * onSearch
     */
    onSearch(flag) {
      this.selectedItems = [];
      this.selectedLeadApproved = [];
      if (flag) {
        this.searchParams = { ...this.searchParams, ...this.searchInputs };
        this.searchParams["currentPage"] = 1;
        if (this.searchParams["inspection_frequency"] === 3) {
          this.getOtherItems();
        } else {
          this.getItems();
        }
      } else {
        this.searchParams = {
          ...this.searchParams,
          ...this.searchLeadApprovedInputs,
        };
        this.searchParams["currentPage"] = 1;
        this.getListLeadApproved();
      }
    },
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },
    onChangeSearchLeadApprovedInputs({ name, value }) {
      let searchLeadApprovedInputs = { ...this.searchLeadApprovedInputs };
      searchLeadApprovedInputs[name] = value;
      this.searchLeadApprovedInputs = searchLeadApprovedInputs;
    },

    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      if (name == "customDate" || name == "customMonth") {
        searchParams.inspection_date_from = value.fromVal;
        searchParams.inspection_date_to = value.toVal;
      } else {
        searchParams[name] = format(new Date(value + "/01"), "yyyy/MM/dd");
      }
      this.searchParams = searchParams;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      if (
        this.searchParams.inspection_frequency ==
        INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_GUIDANCE.id
      ) {
        this.getListLeadApproved();
      } else if (this.searchParams.inspection_frequency === 3) {
        this.getOtherItems(name);
      } else {
        this.getItems(name);
      }
    },

    /**
     * (Common))
     * onChangeSortParams
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      if (
        this.searchParams.inspection_frequency ==
        INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_GUIDANCE.id
      ) {
        this.getListLeadApproved();
      } else if (this.searchParams.inspection_frequency === 3) {
        this.getOtherItems();
      } else {
        this.getItems();
      }
    },
    // resetSearchParams
    resetSearchParams(fieldChanged) {
      let copy = this.searchParams;
      this.searchParams = { ...INITIAL_ITEM_SEARCH_PARAMS };
      this.searchInputs = { ...INITIAL_ITEM_SEARCH_INPUTS };
      this.searchLeadApprovedInputs = { ...INITIAL_ITEM_LEAD_APPROVED };
      this.selectedLeadApproved = [];
      this.selectedItems = [];
      if (!fieldChanged) {
        this.searchParams.inspection_date_from = copy.inspection_date_from;
        this.searchParams.inspection_date_to = copy.inspection_date_to;
      }
    },

    async openItemForm(item) {
      let param = {
        field_id: this.currentSite.field_id,
        ids: item.ids,
      };
      const result = await Store.dispatch(`${STORE}/getByIds`, param);
      if (!result.hasError) {
        this.editedItem = _.cloneDeep(result.data.contents.entries);
        this.popups.isShowInspecForm1 = true;
      } else {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },

    closeItemForm() {
      this.popups.isShowInspecForm1 = false;
      this.$nextTick(() => {
        this.editedItem = {};
      });
    },

    closeDialog() {
      this.popups.isShowApprovalDialog = false;
      this.popups.isShowDialog = false;
      this.popups.isShowGuidanceDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    actionDialog() {
      let action = this.popups.nameAction;
      if (action === INSPECTION_RESULT_CATEGORY.REJECT_APPROVE) {
        this.changeApproveItems(0);
      } else if (action === INSPECTION_RESULT_CATEGORY.APPROVE_ITEMS) {
        this.changeApproveItems(1);
      } else if (action === INSPECTION_RESULT_CATEGORY.APPROVE_ALL_ITEMS) {
        this.approveAll();
      } else if (action === INSPECTION_RESULT_CATEGORY.GUIDANCE) {
        this.guidence();
      } else if (action === INSPECTION_RESULT_CATEGORY.GUIDANCE_ALL) {
        this.guidenceAll();
      }
    },

    rejectConfirm() {
      this.popups.isShowDialog = true;
      this.popups.messageDialog =
        ATTRIBUTE_DIALOG_ARRRAY.CANCEL_APPROVAL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.CANCEL_APPROVAL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.REJECT_APPROVE;
    },

    approveConfirm() {
      this.popups.isShowApprovalDialog = true;
      this.popups.messageDialog = ATTRIBUTE_DIALOG_ARRRAY.APPROVAL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.APPROVAL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.APPROVE_ITEMS;
    },

    approveAllConfirm() {
      if (this.items.length == 0) return;
      this.popups.isShowApprovalDialog = true;
      this.popups.messageDialog = ATTRIBUTE_DIALOG_ARRRAY.APPROVAL_ALL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.APPROVAL_ALL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.APPROVE_ALL_ITEMS;
    },

    guidenceConfirm() {
      this.popups.isShowGuidanceDialog = true;
      this.popups.messageDialog = ATTRIBUTE_DIALOG_ARRRAY.GUIDANCE_ALL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.GUIDANCE_ALL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.GUIDANCE;
    },

    guidenceAllConfirm() {
      if (this.items.length == 0) return;
      this.popups.isShowGuidanceDialog = true;
      this.popups.messageDialog = ATTRIBUTE_DIALOG_ARRRAY.GUIDANCE_ALL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.GUIDANCE_ALL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.GUIDANCE_ALL;
    },

    async getItems(dataGet) {
      this.isLoading = true;
      if (this.currentSite.field_id) {
        let apiParams = {
          ...this.apiParams,
          inspection_frequency:
            dataGet && typeof dataGet == "number"
              ? dataGet
              : this.apiParams.inspection_frequency,
        };
        if (
          this.loginUser?.company_type === COMPANY_TYPE.PATNER ||
          this.loginUser?.company_type === COMPANY_TYPE.RENTAL
        ) {
          this.TABLE_LABELS = INSPECTIONS_TABLE_LABELS;
          delete apiParams?.company_id;
          delete apiParams?.company_inspector;
        }
        if (this.apiParams.inspection_frequency === 1) {
          let inspection_date_from = new Date(apiParams.inspection_date_from);
          let inspection_date_to = new Date(apiParams.inspection_date_to);
          apiParams.inspection_date_from = format(
            new Date(
              inspection_date_from.getFullYear(),
              inspection_date_from.getMonth(),
              1
            ),
            "yyyy/MM/dd"
          );
          apiParams.inspection_date_to = format(
            new Date(
              inspection_date_to.getFullYear(),
              inspection_date_to.getMonth() + 1,
              0
            ),
            "yyyy/MM/dd"
          );
        }
        if (this.apiParams.inspection_frequency === 3)
          apiParams.inspection_frequency = 0;
        await Store.dispatch(`${STORE}/get`, apiParams).then(() => {
          this.isLoading = false;
        });
      }
    },

    async getOtherItems() {
      this.isLoading = true;
      if (this.currentSite.field_id) {
        let apiParams = { ...this.apiParams };
        await Store.dispatch(`${STORE}/getListOtherCompany`, apiParams).then(
          () => {
            this.isLoading = false;
          }
        );
      }
    },

    /**
     * change approve items
     */
    async changeApproveItems(approved_status) {
      const ids = [];
      this.selectedItems.forEach((e) => {
        e.ids.forEach((id) => {
          ids.push(id);
        });
      });
      if (ids == null || ids.length == 0) return;
      let params = {
        ids,
        approved_status,
        inspection_frequency: this.apiParams.inspection_frequency,
      };

      const result = await Store.dispatch(
        `${STORE}/updateStatusByGroup`,
        params
      );

      if (!result.hasError) {
        if (result.data.contents.entries.update_status) {
          // update success
          this.closeDialog();
          this.getItems();
          Store.dispatch("Toast/show", {
            status: 200,
            message:
              approved_status == 0 ? "承認を解除しました" : "承認しました",
          });
        } else {
          this.closeDialog();
          this.popups.isShowUpdateErrorDialog = true;
        }
      } else {
        this.closeDialog();
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },
    async approveAll() {
      let apiParams = { ...this.apiParams };
      if (apiParams.inspection_frequency === 0) {
        apiParams.company_inspector = this.loginUser.company_id;
      }
      const result = await Store.dispatch(`${STORE}/approvalSeries`, apiParams);
      if (!result.hasError) {
        if (result.data.contents.entries.update_status) {
          this.closeDialog();
          this.getItems();
          Store.dispatch("Toast/show", {
            status: 200,
            message: "全件承認しました",
          });
        } else {
          this.closeDialog();
          this.popups.isShowUpdateErrorDialog = true;
        }
      } else {
        this.closeDialog();
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },

    updateSafeSignId(data) {
      if (data.isSecond) {
        this.popups.safeSignId2 = data.id;
        this.popups.isChanged2 = true;
      } else {
        this.popups.safeSignId1 = data.id;
        this.popups.isChanged1 = true;
      }
    },

    updateSafeSign() {
      if (this.popups.isChanged1) {
        this.commitUpdateSafeSign(this.popups.safeSignId1);
      }
      if (this.popups.isChanged2) {
        this.commitUpdateSafeSign(this.popups.safeSignId2, true);
      }
    },

    async commitUpdateSafeSign(id, isSecond = false) {
      const apiParams = {
        field_id: this.currentSite.field_id,
        lead_approved_month: this.searchParams.lead_approved_month,
        company_id: this.popups.companyId,
        safe_sign_user_id: id,
        is_second: isSecond ? 1 : 0,
        is_approval: this.listInspectionApprovalStatus[0]?.company_type,
      };
      const URL = `${process.env.VUE_APP_API_BASE_URL}/reports/update_safe_sign`;
      axios({
        url: URL,
        method: "post",
        params: apiParams,
        timeout: 60000,
        interceptors: {
          request: axios.interceptors.request.use((config) => {
            const GRN = JSON.parse(sessionStorage.getItem("GRN"));
            sessionStorage.getItem("GRN") &&
              (config.headers.Authorization = `${GRN.Login.api_token}`);
            return config;
          }),
        },
      })
        .then((response) => {
          if (response.status == 200) {
            Store.dispatch("Toast/show", {
              status: 200,
              message: "安全承認しました",
            });
          } else {
            const error_status = response.status;
            let error_message = "";
            switch (true) {
              case /401/.test(error_status):
                error_message = "認証エラー";
                break;
              default:
                error_message = "システムエラー";
                break;
            }
            Store.dispatch(
              "Error/show",
              {
                status: response.data.status,
                message: error_message,
              },
              { root: true }
            );
          }
        })
        .catch(() => {
          this.closePrintDialog();
          Store.dispatch(
            "Error/show",
            {
              status: 500,
              message: "システムエラー",
            },
            { root: true }
          );
        });
    },

    async guidence() {
      if (!this.popups.companyId) return;
      const apiParams = {
        field_id: this.currentSite.field_id,
        inspection_date_from: this.searchParams.inspection_date_from,
        inspection_date_to: this.searchParams.inspection_date_to,
        company_id: this.popups.companyId,
      };
      const result = await Store.dispatch(
        `${STORE}/addLeadApproved`,
        apiParams
      );
      if (!result.hasError) {
        this.closeDialog();
        this.getListLeadApproved();
        this.getCompanyByFields();
        this.openDialogTemplate("prevGuildDance", this.popups.companyId);
        Store.dispatch("Toast/show", {
          status: 200,
          message: "指導しました",
        });
      } else {
        this.closeDialog();
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },

    async guidenceAll() {
      const apiParams = {
        field_id: this.currentSite.field_id,
        inspection_date_from: this.searchParams.inspection_date_from,
        inspection_date_to: this.searchParams.inspection_date_to,
      };
      const result = await Store.dispatch(
        `${STORE}/addLeadApproved`,
        apiParams
      );
      if (!result.hasError) {
        // close dialog
        this.closeDialog();
        if (this.searchParams?.inspection_frequency === 3) {
          this.getOtherItems();
        } else if (this.searchParams?.inspection_frequency === 2) {
          this.getListLeadApproved();
        } else {
          this.getItems();
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: "全件指導しました",
        });
      } else {
        this.closeDialog();
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },

    onChangeTableTab(id, fieldChanged = false) {
      this.isDialogComfirm = true;
      this.resetSearchParams(fieldChanged);
      const searchParams = { ...this.searchParams };
      searchParams.inspection_frequency = id;
      // 作業前点検の他社インデックスが異なる為、id変更が影響範囲が大きい為、idx追加
      if ([0].includes(this.loginUser.company_type)) {
        switch (id) {
          case 0:
            searchParams.idx = id;
            break;
          case 1:
            searchParams.idx = 2;
            break;
          case 2:
            searchParams.idx = 3;
            break;
          case 3:
            searchParams.idx = 1;
            break;
        }
      } else {
        searchParams.idx = id;
      }
      this.checkCompanyType();
      this.searchParams = searchParams;
      if (searchParams.inspection_frequency === 0) {
        this.TABLE_LABELS = INSPECTIONS_TABLE_LABELS;
        this.getItems();
      } else if (
        searchParams.inspection_frequency ==
        INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_GUIDANCE.id
      ) {
        this.TABLE_LABELS = this.INSPECTION_GUIDANCE_LABELS;
        this.getListLeadApproved();
        this.getCompanyByFields();
      } else if (searchParams.inspection_frequency === 3) {
        this.TABLE_LABELS = this.CONTRACTOR_INSPECTIONS_TABLE_LABELS_DATE2;
        this.isDialogComfirm = false;
        this.getOtherItems();
      } else {
        this.TABLE_LABELS = this.INSPECTIONS_TABLE_LABELS;
        this.getItems();
      }
      this.getListCompanyByField();
    },

    openPrint() {
      this.popups.isShowPrintDialog = true;
    },

    closePrintDialog() {
      this.popups.isShowPrintDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.selectedLeadApproved = [];
      });
    },

    closeNoticeDialog(status) {
      const STORAGE_KEY = "LOGGED_IN";
      if (status) {
        let notifyId = JSON.parse(localStorage.getItem(STORAGE_KEY) || "[]");
        notifyId.push(this.NOTIFICATION);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(notifyId));
      }
      this.popups.isShowNoticeDialog = false;
    },

    // print machine
    async print(type) {
      this.isLoading = true;
      let ids = [];
      let params = {};
      let URL_EXPORT_FILE = `${process.env.VUE_APP_API_BASE_URL}/inspections/get_machine_prework_checklist`;
      if (
        this.searchParams.inspection_frequency ==
        INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_GUIDANCE.id
      ) {
        ids = this.selectedLeadApproved.map((items) => items.company_id);
        params = {
          file_code: type,
          field_id: this.currentSite.field_id,
          inspection_month: this.searchParams.lead_approved_month,
          company_ids: ids,
        };
        URL_EXPORT_FILE = `${process.env.VUE_APP_API_BASE_URL}/reports/inspection_approval_status_list`;
      } else if (this.searchParams.inspection_frequency == 3) {
        ids = this.selectedItems.map((items) => items.acceptant_number);
        params = {
          file_code: type,
          acceptant_number: ids,
          inspection_date_from: this.searchParams.inspection_date_from,
          inspection_date_to: this.searchParams.inspection_date_to,
          leading_status: this.searchParams.leading_status,
          approved_status: this.searchParams.approved_status,
          inspection_frequency: 0,
        };
      } else {
        ids = this.selectedItems.map((items) => items.acceptant_number);
        params = {
          file_code: type,
          acceptant_number: ids,
          inspection_date_from: this.searchParams.inspection_date_from,
          inspection_date_to: this.searchParams.inspection_date_to,
          approved_status: this.searchParams.approved_status,
          inspection_frequency: this.searchParams.inspection_frequency,
        };
      }
      axios({
        url: URL_EXPORT_FILE,
        method: "GET",
        params: params,
        timeout: 2 * 60000,
        interceptors: {
          request: axios.interceptors.request.use((config) => {
            const GRN = JSON.parse(sessionStorage.getItem("GRN"));
            sessionStorage.getItem("GRN") &&
              (config.headers.Authorization = `${GRN.Login.api_token}`);
            return config;
          }),
        },
      })
        .then((response) => {
          if (response.data.status_code == 200) {
            this.closePrintDialog();
            const { file, file_name } = response.data.contents;
            const byteCharacters = atob(file);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const url = window.URL.createObjectURL(new Blob([byteArray]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file_name);
            document.body.appendChild(link);
            link.click();
          } else {
            this.closePrintDialog();
            const error_status = response.data.status_code;
            let error_message = "";
            switch (true) {
              case /401/.test(error_status):
                error_message = "認証エラー";
                break;
              case /404/.test(error_status):
                error_message = "データ情報が取得できません";
                break;
              case /503/.test(error_status):
                error_message = "メンテナンス中です";
                break;
              default:
                error_message = "システムエラー";
                break;
            }
            Store.dispatch(
              "Error/show",
              {
                status: response.data.status,
                message: error_message,
              },
              { root: true }
            );
          }
        })
        .catch((err) => {
          this.closePrintDialog();
          let error_message = "";
          switch (true) {
            case err.toString().includes("404"):
              error_message = "出力データ情報がありません";
              break;
            default:
              error_message = "システムエラー";
              break;
          }
          Store.dispatch(
            "Error/show",
            {
              status: 500,
              message: error_message,
            },
            { root: true }
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    /**
     * get data select 所有会社
     */
    async getListCompany() {
      if (!this.currentSite.field_id) return;
      let fieldApiParams = {
        field_id: this.currentSite.field_id,
      };
      const response0 = await Store.dispatch(`FieldUser/get`, fieldApiParams);
      const { entries } = response0.data.contents;
      let listIds = [];
      if (!response0.hasError) {
        listIds = [...new Set(entries.map((entry) => entry.company_id))];
      }
      let apiParams = { company_type: [COMPANY_TYPE.RENTAL] };
      const response1 = await Store.dispatch(`Company/get`, apiParams);
      if (!response1.hasError) {
        const { entries } = response1.data.contents;
        let ids = [];
        ids.push(...listIds);
        ids.push(...entries.map((item) => item.id));
        const response2 = await Store.dispatch(`Company/getCompanyByIds`, {
          ids,
        });
        if (!response2.hasError) {
          const { entries } = response2.data.contents;
          this.COMPANY_ITEM = [...entries];
          this.COMPANY_ITEM.unshift({
            id: null,
            name: "",
          });
        }
      }
    },
    async getListCompanyByField() {
      if (this.currentSite.field_id) {
        let apiParams = {
          field_id: this.currentSite.field_id,
        };
        await Store.dispatch(`Sites/getCompanyByField`, apiParams);
      }
    },
    async getNotification() {
      const STORAGE_KEY = "LOGGED_IN";
      if (this.prevRoute.path == "/") {
        const response = await Store.dispatch(`CmnMst/getNotification`);
        if (!response.hasError) {
          const { entries } = response.data.contents;
          if (entries !== null) {
            this.NOTIFICATION = entries.id;
            let notifyId = JSON.parse(
              localStorage.getItem(STORAGE_KEY) || "[]"
            );
            if (!notifyId.includes(this.NOTIFICATION)) {
              this.popups.isShowNoticeDialog = true;
              this.popups.maintainDate = entries.date;
              this.popups.maintainTime = entries.time;
              this.popups.messageDialog = entries.content;
            }
          }
        }
      }
    },
    closeDialogUpdateError() {
      this.popups.isShowUpdateErrorDialog = false;
    },
    async getListLeadApproved() {
      this.isLoading = true;
      await Store.dispatch(
        `FieldUser/getListLeadApproved`,
        this.apiGetListLeadApprovedParams
      ).then(() => {
        this.isLoading = false;
      });
    },
    async getCompanyByFields() {
      if (this.currentSite.field_id) {
        await Store.dispatch(`Sites/getCompanyByField`, {
          field_id: this.currentSite.field_id,
        });
      }
    },
    openDialogTemplate(template, companyIds) {
      if (template == "prevGuildDance") {
        this.getInspectionApprovalStatus(companyIds);
        this.popups.companyId = companyIds;
      }
    },
    async getInspectionApprovalStatus(companyIds) {
      let params = {
        inspection_month: this.searchParams.lead_approved_month,
        company_ids: [companyIds],
        field_id: this.currentSite.field_id,
      };
      await Store.dispatch("Inspections/getInspectionApprovalStatus", {
        params,
      })
        .then((response) => {
          if (response.data) {
            this.listInspectionApprovalStatus = response.data.contents.entries;
            this.popups.isShowDialogTemplateGuildDance = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeDialogTemplate() {
      this.popups.isShowDialogTemplateGuildDance = false;
      this.popups.companyId = null;
      this.popups.isChanged1 = false;
      this.popups.isChanged2 = false;
    },
    onOpenCustomBusinessForm() {
      this.popups.isShowCustomBusinessForm = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.preview-header {
  position: sticky;
  top: 0;
  background-color: $primary_color;
  border-bottom: 1px solid $border_color;
  z-index: 2;
}
</style>
