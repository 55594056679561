<template>
  <div class="tab-one-content">
    <v-btn color="primary">
      <label for="formFileLg">ファイルを選択</label>
      <input
        class="d-none"
        type="file"
        accept="image/gif, image/jpeg, image/png"
        ref="fileInput"
        id="formFileLg"
        @input="selectImgFile"
      />
    </v-btn>
    <div class="img-bg">
      <img v-bind:src="urlFile" alt="" class="w-img" />
      <Loader :isLoading="isLoading"></Loader>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";

// Components
import Loader from "@/components/forms/elements/Loader";

const TYPE_FILE = {
  gif: "image/gif",
  jpeg: "image/jpeg",
  png: "image/png",
};
export default {
  name: "TabOneContent",
  components: {
    Loader,
  },
  data() {
    return {
      enable: false,
      fileBase64: null,
      fileName: null,
      urlFile: null,
      beUrl: null,
      isLoading: false,
    };
  },
  props: {
    filePreview: {
      type: String,
      default: null,
    },
    isTemp: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    let beUrl = process.env.VUE_APP_API_BASE_URL;
    let currentIndex = beUrl.lastIndexOf("/api");
    this.beUrl = beUrl.substring(0, currentIndex);
    this.$watch(
      () => [this.isTemp, this.filePreview],
      (newValue) => {
        if (newValue[0]) {
          this.urlFile = newValue[1];
        } else {
          const imageUrl = `${
            this.beUrl + newValue[1]
          }?timestamp=${new Date().getTime()}`;
          this.urlFile = imageUrl;
        }
      },
      { immediate: true, deep: true }
    );
  },
  watch: {
    getFilePreview() {
      this.urlFile = this.beUrl + this.filePreview;
    },
  },
  computed: {
    hasImage() {
      return this.fileBase64 != null;
    },
    getFilePreview() {
      return this.filePreview;
    },
  },
  methods: {
    selectImgFile() {
      let fileInput = this.$refs.fileInput;
      let imgFile = fileInput.files;
      this.fileName = imgFile[0]["name"];
      let isVai = this.validate(imgFile[0]);
      if (imgFile && imgFile[0] && isVai) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.fileBase64 = e.target.result;
          this.urlFile = e.target.result;
          this.$emit("changeFile", this.hasImage);
        };
        reader.readAsDataURL(imgFile[0]);
      }
    },
    validate(file) {
      let _10m = 10000000;
      if (
        file.type === TYPE_FILE.gif ||
        file.type === TYPE_FILE.jpeg ||
        file.type === TYPE_FILE.png
      ) {
        this.enable = false;
      } else {
        Store.dispatch(
          "Error/show",
          {
            status: "500",
            message: "jpg, png, jpegファイルのみアップロード可能です",
          },
          { root: true }
        );
        return false;
      }
      if (file.size > _10m) {
        this.enable = true;
        this.urlFile = null;
        Store.dispatch(
          "Error/show",
          { status: "500", message: "データ容量は10MB以下でお願いします" },
          { root: true }
        );
        return false;
      } else {
        this.enable = false;
      }
      return true;
    },
    cleanImage() {
      this.$refs.fileInput.value = null;
    },
    getInfoFile() {
      return {
        fileName: this.fileName,
        fileBase64: this.fileBase64,
      };
    },
    getValidateStatus() {
      return this.enable;
    },
    setLoading(bl) {
      this.isLoading = bl;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.tab-one-content {
  box-shadow: none !important;
  border-radius: 0 !important;
}
.img-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.w-img {
  max-height: 700px;
  max-width: 700px;
}
</style>
