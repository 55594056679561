<template>
  <FormDialogLayout
    :right="30"
    :width="40"
    className="user-form"
    :editable="isNewItem"
  >
    <template #header>
      <v-app-bar flat>
        <v-toolbar-title>
          {{
            isNewItem
              ? isField
                ? formtitle + DIALOG_TITLE_ADDITIONS.FIELD_ADD
                : formtitle + DIALOG_TITLE_ADDITIONS.ADD
              : formtitle
          }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable"
          class="mr-5"
          color="primary"
          @click="onEditable"
        >
          {{ DIALOG_BUTTON_NAMES.EDIT }}
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <UserFormPage
              :editable="editable"
              :item="formValues"
              :isNewItem="isNewItem"
              :isEmailValid="isEmailValid"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
              @onChanged="checkEmailExits"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <Popup width="500px" :dialog="isShowConfirmDialog">
        <ConfirmDialog
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          :isClose="true"
          :isCenter="false"
          title="フォームを閉じる確認"
          text="フォームを閉じますがよろしいですか？
        入力内容は保存されません。

        このページから移動してもよろしいですか？"
        />
      </Popup>
    </template>
    <template #footer>
      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          {{ DIALOG_BUTTON_NAMES.CANCEL }}
        </v-btn>
        <v-btn
          v-if="editable && isNewItem"
          class="mx-5"
          :disabled="!valid || !isEmailValid"
          color="primary"
          :key="continueSubmitKey"
          @click="onSubmit(true)"
        >
          {{ DIALOG_BUTTON_NAMES.CONTINUE }}
        </v-btn>
        <v-btn
          v-if="editable"
          class="ml-5"
          :disabled="!valid || !isEmailValid"
          color="primary"
          :key="submitKey"
          @click.once="onSubmit(false)"
        >
          {{ DIALOG_BUTTON_NAMES.SAVE }}
        </v-btn>
      </v-toolbar>
      <Loader :isLoading="isLoading"></Loader>
    </template>
  </FormDialogLayout>
</template>

<script>
import { Store } from "@/store/Store.js";
import { TITLE, FORMS, USER_INITAL_ITEM, USER_ROLE } from "@/constants/USER";
import {
  COMPANY_TYPE,
  DIALOG_BUTTON_NAMES,
  DIALOG_TITLE_ADDITIONS,
} from "@/constants/COMMON";
import { ValidationObserver } from "vee-validate";

// Components
import Popup from "@/components/common/Popup";
import FormDialogLayout from "@/components/layout/FormDialogLayout";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import UserFormPage from "@/components/forms/users/UserFormPage";
import ValidationCallback from "@/components/forms/elements/ValidationCallback";
import Loader from "@/components/forms/elements/Loader";

const STORE = "Users";

export default {
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialogLayout,
    ConfirmDialog,
    UserFormPage,
    Loader,
  },
  data: () => {
    return {
      DIALOG_BUTTON_NAMES,
      DIALOG_TITLE_ADDITIONS,
      valid: false,
      isEmailValid: true,
      isLoading: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      TITLE,
      FORMS,
      formValues: { ...USER_INITAL_ITEM },
      beforeUpdateItem: {},
      submitKey: false,
      continueSubmitKey: true,
    };
  },
  props: {
    isNewItem: Boolean,
    isField: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [Store.getters[`${STORE}/getUserDetail`], this.editable],
      (data) => {
        if (!this.isNewItem) {
          this.formValues = { ...data[0] };
          this.beforeUpdateItem = { ...data[0] };
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    loginUser() {
      return Store.state.Login.user;
    },
    currentSite() {
      return Store.state.GlobalHeader.selectedSite;
    },
    isAdmin() {
      if (
        (this.loginUser.company_type == COMPANY_TYPE.OWNER ||
          this.loginUser.company_type == COMPANY_TYPE.ADMIN) &&
        this.loginUser.role == USER_ROLE[0].id
      ) {
        return true;
      }
      return false;
    },
    formtitle() {
      return this.isNewItem ? this.TITLE.NEW : this.TITLE.EDIT;
    },
  },
  methods: {
    formUpdate(params) {
      this.formValues = { ...this.formValues, ...params };
    },

    onEditable() {
      this.editable = true;
    },

    onDetail() {
      this.editable = false;
    },
    async checkEmailExits() {
      if (this.formValues.email.length <= 0) {
        return true;
      }
      this.isLoading = true;
      let apiParams = {
        email: this.formValues.email,
      };
      const result = await Store.dispatch(
        `${STORE}/checkEmailExits`,
        apiParams
      );
      if (result.hasError) {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
        this.isLoading = false;
        this.isEmailValid = false;
        return false;
      }
      const entries = result.data.contents.entries;
      if (entries.isExits) {
        this.keySubmit = !this.keySubmit;
        Store.dispatch("Error/show", {
          status: 200,
          message: "このメールアドレスは使用中です",
        });
        this.isLoading = false;
        this.isEmailValid = false;
        return false;
      }
      this.isLoading = false;
      this.isEmailValid = true;
      return true;
    },
    async onSubmit(flag) {
      this.isLoading = true;
      const apiParams = { ...this.formValues };
      const hasId = "id" in this.formValues;
      if (!hasId && this.isAdmin && apiParams.role == 0)
        apiParams.invite_flg = 1;
      if (this.isAdmin) apiParams.admin_user_flg = 1;
      const result = await Store.dispatch(
        hasId ? `${STORE}/update` : `${STORE}/post`,
        apiParams
      );
      if (result.hasError) {
        if (flag) this.continueSubmitKey = !this.continueSubmitKey;
        else this.submitKey = !this.submitKey;
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      } else {
        if (this.isNewItem) {
          if (this.isField) {
            this.asignToField(result.data.contents.entries.id);
          }
          if (flag) {
            this.$emit("reOpen");
          } else {
            this.$emit("cancel");
          }
        } else {
          this.getItems(apiParams.id);
          this.onDetail();
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: hasId
            ? "更新しました"
            : this.isField
            ? "登録・招待・アサインしました"
            : "登録・招待しました",
        });
      }
      this.isLoading = false;
    },

    updateValidate({ valid }) {
      this.valid = valid;
    },

    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.formValues = { ...this.beforeUpdateItem };
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },

    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    async getItems(id) {
      if (!this.isNewItem) {
        Store.dispatch(`${STORE}/getUserDetail`, id);
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    async asignToField(newId) {
      const field_user_ids = [];
      const user_ids = [newId];
      const apiParams = {
        field_id: this.currentSite.field_id,
        user_ids,
        field_user_ids,
      };
      await Store.dispatch("FieldUser/update", apiParams);
    },
  },
};
</script>
