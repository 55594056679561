<template>
  <FormDialogLayout>
    <template #header>
      <v-app-bar flat>
        <v-toolbar-title> カスタム帳票 </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="(form, key) in CUSTOM_BUSINESS_FORM_HEADER"
              :key="key"
            >
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <v-container>
        <SearchFormWrapper>
          <InputText
            class="search width-small"
            placeholder="機械名"
            name="middle_classification"
            :editable="true"
            :values="searchInputs"
            @onInput="onChangeSearchInputs"
          />
          <InputText
            class="search width-middle"
            placeholder="仕様・能力"
            name="machine_name"
            :editable="true"
            :values="searchInputs"
            @onInput="onChangeSearchInputs"
          />
          <InputText
            class="search width-middle"
            placeholder="管理名（呼名）"
            name="machine_field_name"
            :editable="true"
            :values="searchInputs"
            @onInput="onChangeSearchInputs"
          />
          <SelectWithFilter
            class="search width-middle"
            placeholder="所有会社"
            name="company_id"
            :items="companyItems"
            :editable="true"
            :values="searchInputs"
            @onInput="onChangeSearchInputs"
          />
          <SelectWithFilter
            class="search width-middle"
            placeholder="点検会社"
            name="company_inspector"
            :editable="true"
            :values="searchInputs"
            :items="companyInspector"
            @onInput="onChangeSearchInputs"
          />
          <v-btn class="search" color="primary" @click="onSearch"> 検索 </v-btn>
        </SearchFormWrapper>
        <v-data-table
          v-model="selectedItems"
          :headers="CUSTOM_BUSINESS_FORM_TABLE_HEADER"
          :items="items"
          :items-per-page="items.length"
          :height="params.mainHeight - 100"
          fixed-header
          hide-default-footer
          disable-sort
          show-select
          noDataText="データがありません。"
        >
        </v-data-table>
      </v-container>
      <Popup
        width="302mm"
        :dialog="popups.isShowDialogTemplateGuildDance"
        class="dialog-template"
      >
        <FormDialogLayout :right="20" :width="60" className="custom-form">
          <template #header>
            <v-app-bar flat>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="popups.isShowDialogTemplateGuildDance = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn></v-app-bar
            >
          </template>
          <template #main>
            <TemplateGuildDance
              :listInspectionApprovalStatus="listInspectionApprovalStatus"
              :isView="true"
            >
            </TemplateGuildDance
          ></template>
          <template #footer>
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
          /></template>
        </FormDialogLayout>
      </Popup>
      <Popup width="500px" :dialog="popups.isShowPrintDialog">
        <ConfirmDialog
          @close="popups.isShowPrintDialog = false"
          @yes="print"
          :isExport="true"
          title="点検表出力"
        />
      </Popup>
      <Loader :isLoading="popups.isLoading"></Loader>
    </template>
    <template #footer>
      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn icon @click="popups.isShowPrintDialog = true">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <v-btn
          class="ml-5"
          color="primary"
          @click="getInspectionApprovalStatus"
        >
          {{ DIALOG_BUTTON_NAMES.PREVIEW }}
        </v-btn></v-toolbar
      >
    </template>
  </FormDialogLayout>
</template>

<script>
import axios from "axios";
import { ValidationObserver } from "vee-validate";
import { Store } from "@/store/Store.js";
import { DIALOG_BUTTON_NAMES, COMPANY_TYPE } from "@/constants/COMMON";
import {
  CUSTOM_BUSINESS_FORM_HEADER,
  CUSTOM_BUSINESS_FORM_TABLE_HEADER,
} from "@/constants/INSPECTION";

// Components
import Popup from "@/components/common/Popup";
import Pagination from "@/components/forms/elements/Pagination";
import ValidationCallback from "@/components/forms/elements/ValidationCallback";
import FormDialogLayout from "@/components/layout/FormDialogLayout";
import SearchFormWrapper from "@/components/forms/elements/SearchFormWrapper";
import TabSelect from "@/components/forms/elements/TabSelect";
import InputText from "@/components/forms/elements/InputText";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import TemplateGuildDance from "@/views/fields/inspections/templates/TemplateGuildDance";
import Loader from "@/components/forms/elements/Loader";

const STORE = "MachineSites";

export default {
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    Pagination,
    FormDialogLayout,
    ConfirmDialog,
    TabSelect,
    InputText,
    SelectWithFilter,
    SearchFormWrapper,
    TemplateGuildDance,
    Loader,
  },
  data: () => {
    return {
      DIALOG_BUTTON_NAMES,
      CUSTOM_BUSINESS_FORM_HEADER,
      CUSTOM_BUSINESS_FORM_TABLE_HEADER,
      tab: 0,
      companyItems: [],
      companyInspector: [],
      items: [],
      selectedItems: [],
      listInspectionApprovalStatus: [],
      allInspectionApprovalStatus: [],
      searchParams: {
        company_id: null,
        company_inspector: null,
        currentPage: 1,
        totalPage: 1,
      },
      searchInputs: {
        middle_classification: null,
        machine_name: null,
        machine_field_name: null,
        company_id: null,
        company_inspector: null,
      },
      popups: {
        isShowDialogTemplateGuildDance: false,
        isShowPrintDialog: false,
        isLoading: false,
      },
    };
  },
  props: {
    lead_approved_month: {
      type: String,
    },
  },
  mounted() {
    this.getItems();
    this.getListCompany();
    this.getListFieldInspector();
    this.$watch(
      () => Store.getters[`${STORE}/getData`],
      (data) => {
        this.items = data;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },
  computed: {
    currentSite() {
      return Store.state.GlobalHeader.selectedSite;
    },
    apiParams() {
      return {
        field_id: this.currentSite.field_id,
        company_id: this.searchParams.company_id,
        company_inspector: this.searchParams.company_inspector,
        machine_field_name: this.searchParams.machine_field_name,
        machine_name: this.searchParams.machine_name,
        middle_classification: this.searchParams.middle_classification,
      };
    },
  },
  watch: {
    currentSite(newValue, oldValue) {
      if (newValue.field_id == oldValue.field_id) return;
      this.getItems();
      this.getListCompany();
      this.getListFieldInspector();
    },
  },
  methods: {
    async getItems() {
      this.popups.isLoading = true;
      await Store.dispatch(`${STORE}/get`, { params: this.apiParams });
      this.popups.isLoading = false;
    },
    async getListCompany() {
      if (!this.currentSite.field_id) return;
      let fieldApiParams = {
        field_id: this.currentSite.field_id,
      };
      const response0 = await Store.dispatch(`FieldUser/get`, fieldApiParams);
      const { entries } = response0.data.contents;
      let listIds = [];
      if (!response0.hasError) {
        listIds = [...new Set(entries.map((entry) => entry.company_id))];
      }
      let apiParams = { company_type: [COMPANY_TYPE.RENTAL] };
      const response1 = await Store.dispatch(`Company/get`, apiParams);
      if (!response1.hasError) {
        const { entries } = response1.data.contents;
        let ids = [];
        ids.push(...listIds);
        ids.push(...entries.map((item) => item.id));
        const response2 = await Store.dispatch(`Company/getCompanyByIds`, {
          ids,
        });
        if (!response2.hasError) {
          const { entries } = response2.data.contents;
          this.companyItems = [];
          this.companyItems = [...entries];
          this.companyItems.unshift({
            id: null,
            name: "",
          });
        }
      }
    },
    async getListFieldInspector() {
      if (this.currentSite.field_id) {
        let apiParams = {
          field_id: this.currentSite.field_id,
        };
        const res = await Store.dispatch(
          `${STORE}/getListMachineInspector`,
          apiParams
        );
        if (!res.hasError) {
          const data = res.data.contents.entries;
          this.companyInspector = [];
          this.companyInspector = [...data];
          this.companyInspector.unshift({
            id: null,
            name: "",
          });
        }
      }
    },
    async getInspectionApprovalStatus() {
      const companyIds = [
        ...new Set(this.selectedItems.map((item) => item.company_id)),
      ];
      const machineFieldIds = this.selectedItems.map((item) => item.id);
      let params = {
        inspection_month: this.lead_approved_month,
        machine_field_id: machineFieldIds,
        company_ids: companyIds,
        field_id: this.currentSite.field_id,
      };
      await Store.dispatch("Inspections/getInspectionApprovalStatus", {
        params,
      })
        .then((response) => {
          if (response.data) {
            const result = response.data.contents.entries;
            if (result.length > 0) {
              this.searchParams.totalPage = result.length;
              this.allInspectionApprovalStatus = result;
              this.listInspectionApprovalStatus = [result[0]];
            }
            this.popups.isShowDialogTemplateGuildDance = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },
    onSearch() {
      this.searchParams = { ...this.searchParams, ...this.searchInputs };
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      this.getItems();
    },
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.listInspectionApprovalStatus = [
        this.allInspectionApprovalStatus[n - 1],
      ];
    },
    closeForm() {
      this.$emit("cancel");
    },
    print(type) {
      this.isLoading = true;
      let URL_EXPORT_FILE = `${process.env.VUE_APP_API_BASE_URL}/reports/inspection_approval_status_list`;
      let companyIds = [
        ...new Set(this.selectedItems.map((item) => item.company_id)),
      ];
      let machineFieldIds = this.selectedItems.map((item) => item.id);
      let params = {
        file_code: type,
        field_id: this.currentSite.field_id,
        inspection_month: this.lead_approved_month,
        company_ids: companyIds,
        machine_field_id: machineFieldIds,
      };
      axios({
        url: URL_EXPORT_FILE,
        method: "GET",
        params: params,
        timeout: 2 * 60000,
        interceptors: {
          request: axios.interceptors.request.use((config) => {
            const GRN = JSON.parse(sessionStorage.getItem("GRN"));
            sessionStorage.getItem("GRN") &&
              (config.headers.Authorization = `${GRN.Login.api_token}`);
            return config;
          }),
        },
      })
        .then((response) => {
          if (response.data.status_code == 200) {
            const { file, file_name } = response.data.contents;
            const byteCharacters = atob(file);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const url = window.URL.createObjectURL(new Blob([byteArray]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file_name);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((err) => {
          let error_message = "";
          switch (true) {
            case err.toString().includes("404"):
              error_message = "出力データ情報がありません";
              break;
            default:
              error_message = "システムエラー";
              break;
          }
          Store.dispatch(
            "Error/show",
            {
              status: 500,
              message: error_message,
            },
            { root: true }
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
