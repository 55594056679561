var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DefaultLayout',{scopedSlots:_vm._u([{key:"mainHeader",fn:function(){return [_c('SitePortalHeader')]},proxy:true},{key:"page",fn:function(ref){
var layoutParams = ref.layoutParams;
return [_c('TableLayout',{attrs:{"layoutParams":layoutParams,"hideFooter":_vm.searchParams.pageCount >= _vm.searchParams.total_item},scopedSlots:_vm._u([{key:"tableHeader",fn:function(ref){
var updateHeader = ref.updateHeader;
return [_c('TableHeader',{ref:"tableHeader",attrs:{"isAddNew":true,"isUpdate":true,"isDelete":false,"isRepresentative":true,"isSearch":false,"pageTitle":_vm.PAGE_TITLE,"multiRemoveStatus":_vm.disableRemoveBtn,"updateHeader":updateHeader,"isRuleRepresentative":_vm.IS_USER_FIELD,"addButtonTitle":"新規登録"},on:{"openItemForm":function($event){_vm.popups.isShowNewItemForm = true},"onApprove":_vm.onRepresentative,"onReject":_vm.onRepresentative,"openUpdateForm":_vm.openUpdateForm}}),_c('SearchFormWrapper',[_c('InputText',{staticClass:"search width-small",attrs:{"placeholder":"氏名","name":"word","editable":true,"values":_vm.searchInputs},on:{"onInput":_vm.onChangeSearchInputs}}),_c('SelectWithFilter',{staticClass:"search width-middle",attrs:{"placeholder":"所属会社","name":"company_id","items":_vm.COMPANY_LIST,"item_text":"name","editable":true,"values":_vm.searchInputs},on:{"onInput":_vm.onChangeSearchInputs}}),_c('TabSelect',{staticClass:"search",attrs:{"name":"role","items":_vm.FIELD_USER_ROLE,"editable":true,"values":_vm.searchInputs},on:{"onInput":_vm.onChangeSearchInputs}}),_c('SwitchInput',{staticClass:"search",attrs:{"name":"representative_flag","label":"代表者のみ表示","values":_vm.searchInputs,"editable":true},on:{"onInput":_vm.onChangeSearchInputs}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onSearch}},[_vm._v(" 検索 ")])],1),_c('TableSortWrapper',[_c('TableSort',{attrs:{"values":_vm.searchParams,"sort_items":_vm.SORT_ITEMS,"sort_item_text":"name","sort_item_value":"id","page_counts_options":_vm.PAGE_COUNT_OPTIONS,"sort_order_options":_vm.SORT_ORDERS,"total_item":_vm.searchParams.total_item},on:{"onInput":_vm.onChangeSortParams}})],1)]}},{key:"tableBody",fn:function(ref){
var tableHeight = ref.tableHeight;
return [_c('v-data-table',{attrs:{"headers":_vm.TABLE_LABELS,"items":_vm.items,"items-per-page":_vm.searchParams.pageCount,"height":tableHeight,"fixed-header":"","hide-default-footer":"","disable-sort":"","sort-by":"updatedAt","show-select":"","noDataText":"データがありません。","item-key":"id"},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.handleRole(item.role))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cst-overflow-td"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.company_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cst-overflow-td"},[_vm._v(" "+_vm._s(item.company_name)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cst-overflow-td-email"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.representative_flag",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.handleRepresentative(item.representative_flag))+" ")]}},{key:"item.flag_check_past",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFlagCheckPast(item.flag_check_past))+" ")]}},{key:"item.btn_dot",fn:function(ref){
var item = ref.item;
return [(_vm.IS_MACHINE_MANAGER)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"menu-item"},[_c('SwitchInput',{attrs:{"name":"flag_check_past","label":"過去日の点検を許可","values":item,"editable":true},on:{"onInput":function($event){return _vm.onChangeFlagCheckPast($event, item)}}})],1)],1):_vm._e()]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})]}},{key:"tableFooter",fn:function(){return [_c('Pagination',{attrs:{"current":_vm.searchParams.currentPage,"total":_vm.searchParams.totalPage},on:{"pageUpdate":_vm.pageUpdate}})]},proxy:true}],null,true)})]}}])}),_c('Popup',{attrs:{"dialog":_vm.popups.isShowItemForm}},[_c('UpdateUserFieldForm',{ref:"UpdateUserFieldForm",on:{"updateChecked":_vm.updateChecked,"cancel":_vm.closeItemForm}})],1),_c('Popup',{attrs:{"dialog":_vm.popups.isShowNewItemForm}},[_c('UserForm',{key:_vm.popupKey,ref:"UserForm",attrs:{"isNewItem":true,"isField":true},on:{"cancel":_vm.closeItemForm,"reOpen":_vm.reOpen}})],1),_c('Popup',{attrs:{"width":"500px","dialog":_vm.popups.isShowConfirmDialog}},[_c('ConfirmDialog',{attrs:{"hasDetails":false,"isUnlock":_vm.message.flg,"isAccept":!_vm.message.flg,"title":_vm.message.title,"text":_vm.message.text},on:{"close":function($event){return _vm.closeRemoveDialog()},"yes":_vm.onConfirmRepresentative}})],1),_c('Loader',{attrs:{"isLoading":_vm.isLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }