var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DefaultLayout',{scopedSlots:_vm._u([{key:"mainHeader",fn:function(){return [_c('SitePortalHeader')]},proxy:true},{key:"page",fn:function(ref){
var layoutParams = ref.layoutParams;
return [_c('TableLayout',{attrs:{"layoutParams":layoutParams,"hideFooter":true},scopedSlots:_vm._u([{key:"tableHeader",fn:function(ref){return [_c('v-card-title',[_vm._v(_vm._s(_vm.currentSite.name)+" ホーム")])]}},{key:"tableBody",fn:function(ref){return [_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"group-title"},[_vm._v(" "+_vm._s(_vm.getFormattedDate())+" 作業前点検状況 ")]),_c('div',{staticClass:"d-flex justify-space-between group1"},[_c('div',{staticClass:"v-chip"},[_c('div',{staticClass:"flex-column chip-content"},[_c('div',{staticClass:"content-title"},[_vm._v(" "+_vm._s([0, 9].includes(_vm.loginUser.company_type) ? "共有機" : "自社機械")+" ")]),_c('div',{staticClass:"content"},[_c('v-btn',{staticClass:"number-with-divider",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({
                            name: 'Inspections',
                            params: { tab: 0 },
                          })}}},[_vm._v(_vm._s(_vm.data.ownInspection))]),_c('span',{staticClass:"divider"},[_vm._v("/"+_vm._s(_vm.data.ownTotal))]),_c('span',{staticClass:"unit"},[_vm._v("件")])],1)])]),([0, 9].includes(_vm.loginUser.company_type))?_c('div',{staticClass:"v-chip"},[_c('div',{staticClass:"flex-column chip-content"},[_c('div',{staticClass:"content-title"},[_vm._v("持込機械")]),_c('div',{staticClass:"content"},[_c('v-btn',{staticClass:"number-with-divider",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({
                            name: 'Inspections',
                            params: { tab: 3 },
                          })}}},[_vm._v(_vm._s(_vm.data.othersInspection))]),_c('span',{staticClass:"divider"},[_vm._v("/"+_vm._s(_vm.data.othersTotal))]),_c('span',{staticClass:"unit"},[_vm._v("件")])],1)])]):_vm._e()]),_c('div',{staticClass:"d-flex justify-space-between group2"},[_c('div',{staticClass:"v-chip"},[_c('div',{staticClass:"flex-column chip-content"},[_c('div',{staticClass:"content-title"},[_vm._v("未是正不備報告")]),_c('div',{staticClass:"content"},[_c('v-btn',{staticClass:"number",attrs:{"text":""},on:{"click":function($event){_vm.popups.isShowInspectionNG = true}}},[_vm._v(_vm._s(_vm.data.bugs.length))]),_c('span',{staticClass:"unit"},[_vm._v("件")])],1)])]),_c('div',{staticClass:"v-chip"},[_c('div',{staticClass:"flex-column chip-content"},[_c('div',{staticClass:"content-title"},[_vm._v(_vm._s(_vm.TITLE1))]),_c('div',{staticClass:"content"},[_c('v-btn',{staticClass:"number",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({
                            name: 'Inspections',
                            params: { tab: 1 },
                          })}}},[_vm._v(_vm._s(_vm.data.monthlyCount))]),_c('span',{staticClass:"unit"},[_vm._v("件")])],1)])]),_c('div',{staticClass:"v-chip"},[_c('div',{staticClass:"flex-column chip-content"},[_c('div',{staticClass:"content-title"},[_vm._v(_vm._s(_vm.TITLE2))]),_c('div',{staticClass:"content"},[_c('v-btn',{staticClass:"number",attrs:{"text":""},on:{"click":function($event){_vm.popups.isShowNewMachine = true}}},[_vm._v(_vm._s(_vm.data.newMachines.length))]),_c('span',{staticClass:"unit"},[_vm._v("件")])],1)])])])]),_c('div',{staticClass:"d-flex",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"table"},[_c('div',{staticClass:"content-title"},[_vm._v(" 点検実施履歴 "+_vm._s(_vm.inspectionItems.length)+" 件 ")]),_c('v-data-table',{attrs:{"headers":_vm.INSPECTIONS_TABLE_LABELS,"items":_vm.inspectionItems,"items-per-page":1000,"height":300,"fixed-header":"","hide-default-footer":"","disable-sort":"","noDataText":"データがありません。"},scopedSlots:_vm._u([{key:"item.inspection_at",fn:function(ref){
                          var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.convertFormatDate(item))+" ")])]}}],null,true)})],1)])]),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"group-title"},[_vm._v("機械点検レポート")]),_c('div',{staticClass:"chart"},[_c('SearchFormWrapper',[_c('SwitchCustom',{staticClass:"search",attrs:{"name":"isWeekly","values":_vm.searchInputs},on:{"onInput":_vm.onChangeSearchInputs}}),_c('SelectWithFilter',{staticClass:"search width-middle",attrs:{"placeholder":"大分類","name":"major_classification_id","items":_vm.MAJOR_ITEM,"item_text":"major_classification","item_value":"major_classification_id","editable":true,"values":_vm.searchInputs},on:{"onInput":_vm.onChangeSearchInputs}}),_c('SelectWithFilter',{staticClass:"search width-middle",attrs:{"placeholder":"機械名","name":"middle_classification_id","item_text":"middle_classification","item_value":"middle_classification_id","items":_vm.MEDIUM_ITEM,"editable":true,"values":_vm.searchInputs},on:{"onInput":_vm.onChangeSearchInputs}}),_c('SelectWithFilter',{staticClass:"search width-middle",attrs:{"placeholder":"仕様・能力","name":"machine_id","item_text":"machine_name","item_value":"machine_id","items":_vm.ABILITY,"editable":true,"values":_vm.searchInputs},on:{"onInput":_vm.onChangeSearchInputs}})],1),_c('Bar',{attrs:{"chart-options":_vm.chartOptions,"chart-data":_vm.chartData}})],1)])]}}],null,true)}),_c('Popup',{attrs:{"dialog":_vm.popups.isShowInspectionNG}},[_c('inspectionNG',{attrs:{"item":_vm.data.bugs},on:{"close":function($event){_vm.popups.isShowInspectionNG = false}}})],1),_c('Popup',{attrs:{"dialog":_vm.popups.isShowNewMachine}},[_c('newMachines',{attrs:{"item":_vm.data.newMachines},on:{"close":function($event){_vm.popups.isShowNewMachine = false}}})],1)]}}])}),_c('Loader',{attrs:{"isLoading":_vm.isLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }