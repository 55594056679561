<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <FieldMachinesHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :endUseMachineFieldStatus="disablePrintBtn"
              :multiRemoveStatus="disableRemoveBtn"
              :disabelEndUseStatus="disabelEndUseBtn"
              :updateHeader="updateHeader"
              :isQrCode="true"
              :isSearch="false"
              :useFlg="useFlg"
              @exportQrCode="openDialogExport"
              addButtonTitle="新規登録"
              :isEndUseMachineField="isEndUseMachineField"
              @openEndUseMachineFieldDialog="openEndUseMachineFieldDialog"
              @openCancelUseMachineFieldDialog="openCancelUseMachineFieldDialog"
              @openRemoveDialog="openRemoveDialog"
              @openItemForm="openNewItemForm"
              @openPrint="openPrint"
            >
            </FieldMachinesHeader>
            <SearchFormWrapper>
              <InputText
                class="search width-small"
                placeholder="機械名"
                name="middle_classification"
                :editable="true"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <SelectWithFilter
                v-if="showSelectCompany"
                class="search width-middle"
                placeholder="所有会社"
                name="company_id"
                :editable="true"
                :values="searchInputs"
                :items="COMPANY_ITEM"
                @onInput="onChangeSearchInputs"
              />
              <SelectWithFilter
                v-if="showSelectCompany"
                class="search width-middle"
                placeholder="点検会社"
                name="company_inspector"
                :editable="true"
                :values="searchInputs"
                :items="COMPANY_INSPECTOR"
                @onInput="onChangeSearchInputs"
              />
              <InputText
                class="search width-small"
                placeholder="管理名（呼名）"
                name="machine_field_name"
                :editable="true"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <SwitchInput
                class="search"
                name="end_use_flg"
                label="終了した機械のみ表示"
                :editable="true"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <v-btn color="primary" @click="onSearch"> 検索 </v-btn>
            </SearchFormWrapper>
            <TableSortWrapper>
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <template #tableBody="{ tableHeight }">
            <v-data-table
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              show-select
              noDataText="データがありません。"
              @click:row="openItemForm"
            >
              <template v-slot:[`item.new`]="{ item }">
                <v-icon
                  v-if="isNew(item.created_at)"
                  style="color: red !important"
                  >mdi-new-box</v-icon
                >
              </template>
              <template v-slot:[`item.machine_field_name`]="{ item }">
                {{ convertName(item.machine_field_name, 50) }}
              </template>
              <template v-slot:[`item.show_log`]="{ item }">
                <v-btn
                  color="primary"
                  @click.stop="showInspectionHistory(item)"
                >
                  表示
                </v-btn>
              </template>
              <template v-slot:[`item.remarks`]="{ item }">
                <div>
                  {{ convertName(item.remarks, 50) }}
                </div>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <Popup :dialog="popups.isShowItemForm">
      <MachineSitesForm
        :item="editedItem"
        :isNewItem="isNewItem"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
        @deleteIds="getDeleteIds"
        :key="isResetForm"
        ref="MachineSitesForm"
        :keyButton="keyButton"
      />
    </Popup>

    <Popup :dialog="popups.isShowLogForm">
      <MachineSitesInspectionHistory :item="logData" @close="closeLogForm" />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowRemoveDialog">
      <ConfirmDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        :isDelete="true"
        :isFieldMachineDetails="true"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowEndUseMachineFieldDialog">
      <ConfirmDialog
        @close="closeEndUseMachineFieldDialog"
        @yes="endUseMachineFields"
        :hasDetails="false"
        :isCenter="false"
        :isEndUse="true"
        title="機械終了"
        text="機械を終了しようとしています。
        機械を終了すると、
        現場機械一覧に表示されなくなります。

        実行しますか？"
        :items="selectedItems"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowCancelUseMachineFieldDialog">
      <ConfirmDialog
        @close="closeCancelMachineFieldDialog"
        @yes="cancelUseMachineFields"
        :hasDetails="false"
        :isCenter="false"
        :isUndo="true"
        title="終了取消"
        text="終了を取消しようとしています。
        現場機械一覧に表示されます。

        実行しますか？"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowPrintDialog">
      <ConfirmDialog
        @close="closePrintDialog"
        @yes="print"
        :isExport="true"
        title="現場機械リスト出力"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowExportDialog">
      <ConfirmDialog
        @close="closeExportDialog"
        @yes="exportListQrCode"
        :isExport="true"
        title="現場機械QRコード出力"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowConfirmDialog">
      <ConfirmDialog
        @close="closeItemForm"
        :isTwoButton="false"
        :isInspection="true"
        title="連番管理の機械を作成"
        :text="
          editedItem.machine_fields && editedItem.machine_fields.length > 0
            ? editedItem.machine_fields[0].machine_field_name
            : ''
        "
        :items="editedItem.machine_fields"
      />
    </Popup>
    <Loader :isLoading="isLoading"></Loader>
  </div>
</template>
<script>
// Common and functions
import axios from "axios";
import _ from "lodash";
import { Store } from "@/store/Store.js";
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS } from "@/constants/COMMON";
import {
  MACHINE_SITE_TABLE_LABELS,
  MACHINE_SITE_SORT_ITEMS,
  MACHINE_SITE_INITIAL_ITEM,
  MACHINE_SITE_TITLE,
} from "@/constants/MACHINE_SITES";
import {
  HEADER_MENU_ITEMS_FIELD,
  HEADER_MENU_END_USE_FIELD,
} from "@/constants/GLOBALHEADER";
import { COMPANY_TYPE } from "@/constants/COMMON";

// Components
import SwitchInput from "@/components/forms/elements/SwitchInput";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import Pagination from "@/components/forms/elements/Pagination";
import SearchFormWrapper from "@/components/forms/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/forms/elements/TableSortWrapper";
import TableSort from "@/components/forms/elements/TableSort";
import Popup from "@/components/common/Popup";
import Loader from "@/components/forms/elements/Loader";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import MachineSitesForm from "@/components/forms/fields/fieldMachines/MachineSitesForm";
import MachineSitesInspectionHistory from "@/components/forms/fields/fieldMachines/MachineSitesInspectionHistory";
import InputText from "@/components/forms/elements/InputText";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter";
import Label from "@/components/forms/elements/Label";
import TabSelect from "@/components/forms/elements/TabSelect";
import FieldMachinesHeader from "@/views/fields/fieldMachines/components/FieldMachinesHeader";

//page_title
const PAGE_TITLE = MACHINE_SITE_TITLE;

// Number of tables per page
const PAGE_COUNT = 25;

// Number of tables per page option
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// Number of tables per page option
const SORT_ORDERS = TABLE_SORT_ORDERS;

// store name with call api
const STORE = "MachineSites";

// Table header label
const TABLE_LABELS = MACHINE_SITE_TABLE_LABELS;

// Sort element
const SORT_ITEMS = MACHINE_SITE_SORT_ITEMS;

// initial item
const INITIAL_ITEM = MACHINE_SITE_INITIAL_ITEM;

// DOWNLOAD_QR_CODE_URL
const DOWNLOAD_QR_CODE_URL = `${process.env.VUE_APP_API_BASE_URL}/reports/exportMachineFieldQRCode`;

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: "現場機材" };
    },
  },
  components: {
    DefaultLayout,
    TableLayout,
    FieldMachinesHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    Loader,
    ConfirmDialog,
    MachineSitesForm,
    MachineSitesInspectionHistory,
    InputText,
    SelectWithFilter,
    Label,
    TabSelect,
    SwitchInput,
  },
  data() {
    return {
      PAGE_TITLE,
      TABLE_LABELS,
      COMPANY_ITEM: [],
      COMPANY_INSPECTOR: [],
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      items: [],
      selectedItems: [],
      logData: {
        id: null,
        middle_classification: null,
        machine_name: null,
        machine_field_name: null,
      },
      isResetForm: false,
      editedItem: { ...INITIAL_ITEM },
      isNewItem: false,
      searchParams: {
        company_id: null,
        company_inspector: null,
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null,
        asc: true,
      },
      deleteIds: [],
      popups: {
        isShowItemForm: false,
        isShowLogForm: false,
        isShowRemoveDialog: false,
        isShowPrintDialog: false,
        isShowExportDialog: false,
        isShowEndUseMachineFieldDialog: false,
        isShowCancelUseMachineFieldDialog: false,
        isShowConfirmDialog: false,
      },
      searchInputs: {
        middle_classification: null,
        company_id: null,
        company_inspector: null,
        end_use_flg: 0,
      },
      keyButton: false,
      isActive: false,
      isLoading: false,
      useFlg: "機械終了",
      isEndUseMachineField: 0,
    };
  },
  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    this.getListCompany();
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_FIELD.MACHINE_FIELDS.id,
    });
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        this.items = data[0];

        const now = new Date();
        const newDateNow = new Date(
          now.getFullYear(),
          now.getMonth() + 1,
          now.getDate()
        );
        const itemsToMove = this.items.filter((item) => {
          const createdAt = new Date(item.created_at);
          const newCreatedAt = new Date(
            createdAt.getFullYear(),
            createdAt.getMonth() + 1,
            createdAt.getDate()
          );
          const dateDIff = newDateNow.getTime() - newCreatedAt.getTime();
          return dateDIff === 0;
        });
        const remainingItems = this.items.filter((item) => {
          const createdAt = new Date(item.created_at);
          const newCreatedAt = new Date(
            createdAt.getFullYear(),
            createdAt.getMonth() + 1,
            createdAt.getDate()
          );
          const dateDIff = newDateNow.getTime() - newCreatedAt.getTime();
          return dateDIff !== 0;
        });
        const reorderedItems = [...itemsToMove, ...remainingItems];
        this.items = reorderedItems;
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
        this.getListFieldInspector();
      },
      {
        immidiate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.state.GlobalHeader.selectedSite,
      (newValue) => {
        if (newValue?.end_use_flg) {
          this.$router
            .push(HEADER_MENU_END_USE_FIELD.INSPECTOR_FIELD.path)
            .catch((err) => {
              if (err.name !== "NavigationDuplicated") {
                console.error(err);
              }
            });
          return;
        }
        this.getListCompany();
        this.getItems();
      },
      {
        deep: true,
      }
    );
    this.getItems();
  },

  computed: {
    currentSite() {
      return Store.state.GlobalHeader.selectedSite;
    },
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },
    disablePrintBtn() {
      return this.searchParams.end_use_flg === 1;
    },
    apiParams() {
      return {
        field_id: this.currentSite.field_id,
        company_id: this.searchParams.company_id,
        company_inspector: this.searchParams.company_inspector,
        machine_field_name: this.searchParams.machine_field_name,
        sort_value: this.searchParams.sort,
        sort_by: this.searchParams.asc ? 1 : 0,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        middle_classification: this.searchParams.middle_classification,
        end_use_flg: this.searchParams.end_use_flg,
      };
    },
    loginUser() {
      return Store.state.Login.user;
    },
    showSelectCompany() {
      if (
        [COMPANY_TYPE.PATNER, COMPANY_TYPE.RENTAL].includes(
          this.loginUser?.company_type
        )
      )
        return false;
      return true;
    },
    disabelEndUseBtn() {
      return this.selectedItems.length === 0;
    },
  },

  methods: {
    isNew(data) {
      const now = new Date();
      const createdAt = new Date(data);
      const newDateNow = new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        now.getDate()
      );
      const newCreatedAt = new Date(
        createdAt.getFullYear(),
        createdAt.getMonth() + 1,
        createdAt.getDate()
      );
      const dateDIff = newDateNow.getTime() - newCreatedAt.getTime();
      if (dateDIff === 0) return true;
      return false;
    },
    getDeleteIds(deleteIds) {
      this.deleteIds = deleteIds;
    },
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },
    onSearch() {
      this.searchParams = { ...this.searchParams, ...this.searchInputs };
      this.searchParams["currentPage"] = 1;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      this.getItems();
    },
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.getItems();
    },
    async openItemForm(item) {
      const params = { id: item.id };
      const machineSiteInfo = await Store.dispatch(
        `${STORE}/getMachineSitesDetail`,
        { params }
      );
      if (!machineSiteInfo.hasError) {
        this.editedItem = { ...machineSiteInfo.data.contents.entries };
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },
    async endUseMachineFields() {
      const ids = this.selectedItems.map((item) => item.id);
      await Store.dispatch(`${STORE}/endUseMachineField`, { ids });
      this.getItems();
      this.closeEndUseMachineFieldDialog();
    },
    async cancelUseMachineFields() {
      const ids = this.selectedItems.map((item) => item.id);
      await Store.dispatch(`${STORE}/cancelUseMachineField`, { ids });
      this.getItems();
      this.closeCancelMachineFieldDialog();
    },
    async openNewItemForm() {
      let _initial_item = { ...INITIAL_ITEM };
      _initial_item.field_id = this.currentSite.field_id;
      this.editedItem = _.cloneDeep(_initial_item);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },

    closeLogForm() {
      this.popups.isShowLogForm = false;
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      if (this.popups.isShowConfirmDialog == true) {
        this.popups.isShowConfirmDialog = false;
        this.setEmptyDataRegister();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
        this.$nextTick(() => {
          this.selectedItems = [];
        });
      }
      this.$nextTick(() => {
        this.editedItem = { ...INITIAL_ITEM };
      });
    },
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },
    openCancelUseMachineFieldDialog() {
      this.popups.isShowCancelUseMachineFieldDialog = true;
    },
    closeCancelMachineFieldDialog() {
      this.popups.isShowCancelUseMachineFieldDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },
    openEndUseMachineFieldDialog() {
      this.popups.isShowEndUseMachineFieldDialog = true;
    },
    closeEndUseMachineFieldDialog() {
      this.popups.isShowEndUseMachineFieldDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },
    formUpdate(params) {
      this.editedItem = { ...params };
    },
    async getItems() {
      this.isLoading = true;
      if (this.apiParams.end_use_flg != 1) {
        this.useFlg = "機械終了";
        this.isEndUseMachineField = 0;
      } else {
        this.useFlg = "終了取消";
        this.isEndUseMachineField = 1;
      }
      if (this.currentSite.field_id) {
        await Store.dispatch(`${STORE}/get`, { params: this.apiParams });
      }
      this.isLoading = false;
    },
    async removeItems() {
      const ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/delete`, { ids });
      if (!result.hasError) {
        this.selectedItems = [];
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
      this.closeRemoveDialog();
    },
    showInspectionHistory(item) {
      this.logData = {
        id: item.id,
        middle_classification: item.middle_classification,
        machine_name: item.machine_name,
        machine_field_name: item.machine_field_name,
      };
      this.popups.isShowLogForm = true;
    },
    async submitForm(flag) {
      const hasId = "id" in this.editedItem;
      let _editedItem = _.cloneDeep(this.editedItem);
      let imageParams = {
        major_classification_id: _editedItem.major_classification_id,
        middle_classification_id: _editedItem.middle_classification_id,
        image_name: _editedItem.image_name,
        image: _editedItem.image,
      };
      let machineParams = {
        id: _editedItem.machine_id,
        name: _editedItem.machine_name,
        company_id: _editedItem.company_inspector,
        user_id: _editedItem.user_id,
        major_classification_id: _editedItem.major_classification_id,
        middle_classification_id: _editedItem.middle_classification_id,
        remarks: _editedItem.remarks,
      };
      delete _editedItem.acceptant_number;
      delete _editedItem.major_classification_id;
      delete _editedItem.middle_classification_id;
      delete _editedItem.middle_classification;
      delete _editedItem.major_classification;
      delete _editedItem.field_name;
      delete _editedItem.machine_name;
      delete _editedItem.company_name;
      delete _editedItem.company_id;
      delete _editedItem.inspector_name;
      delete _editedItem.temp_id;
      _editedItem.machine_field_inspections.forEach((e) => {
        delete e.modified_flg;
      });
      if (hasId) {
        delete _editedItem.machine_fields;
        _editedItem.quantity = parseInt(_editedItem.quantity);
      } else {
        let _machine_field_inspections =
          _editedItem.machine_field_inspections.filter((e) => e.id == null);
        _editedItem.machine_field_inspections = [..._machine_field_inspections];
        if (_editedItem.type == 2) {
          if (
            _editedItem.machine_fields[0].machine_field_name_prefix
              .machine_field_name_prefix
          ) {
            _editedItem.machine_fields[0].machine_field_name_prefix =
              _editedItem.machine_fields[0].machine_field_name_prefix.machine_field_name_prefix;
          }
          _editedItem.machine_fields[0].quantity = parseInt(
            _editedItem.machine_fields[0].quantity
          );
          _editedItem.machine_fields.forEach((e) => {
            _editedItem.quantity = e.quantity;
          });
          _editedItem.number_quantity = parseInt(_editedItem.quantity);
          _editedItem.machine_field_range = parseInt(
            _editedItem.machine_fields[0].machine_field_name_range
          );
        } else {
          _editedItem.machine_fields.forEach((e) => {
            e.quantity = parseInt(_editedItem.quantity);
          });
        }
        delete _editedItem.quantity;
      }
      if (this.deleteIds?.length > 0) {
        this.deleteIds.forEach((id) => {
          _editedItem.machine_field_inspections =
            _editedItem.machine_field_inspections.filter((item) => {
              return item.id != id;
            });
        });
      }
      _editedItem.machine_field_inspections.forEach((item) => {
        if (typeof item.id === "string") {
          delete item.id;
        }
      });
      let param = hasId
        ? _editedItem
        : {
            ..._editedItem,
            is_web: true,
          };
      const result = await Store.dispatch(
        hasId ? `${STORE}/update` : `${STORE}/add`,
        param
      );
      if (imageParams.image_name && imageParams.image)
        await Store.dispatch("Machines/uploadImage", imageParams);
      await Store.dispatch(hasId ? "Machines/update" : "Machines/add", machineParams);
      this.deleteIds = [];
      if (result.hasError) {
        this.keyButton = !this.keyButton;
        const { data } = result.response;
        let err = data.message;
        let msg = "";
        for (var key of Object.keys(err)) {
          msg += err[key][0];
        }
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: msg,
        });
      } else if (hasId) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        let params = { id: _editedItem.id };
        const resultDetail = await Store.dispatch(
          `${STORE}/getMachineSitesDetail`,
          { params }
        );
        this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
        this.isResetForm = !this.isResetForm;
      } else {
        if (flag) {
          if (_editedItem.type == 2) {
            this.popups.isShowConfirmDialog = true;
            this.getItems();
            return;
          } else {
            this.closeItemForm();
          }
        }
        this.setEmptyDataRegister();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.getItems();
    },

    /**
     * get data select 所有会社
     */
    async getListCompany() {
      if (!this.currentSite.field_id) return;
      let fieldApiParams = {
        field_id: this.currentSite.field_id,
      };
      const response0 = await Store.dispatch(`FieldUser/get`, fieldApiParams);
      const { entries } = response0.data.contents;
      let listIds = [];
      if (!response0.hasError) {
        listIds = [...new Set(entries.map((entry) => entry.company_id))];
      }
      let apiParams = { company_type: [COMPANY_TYPE.RENTAL] };
      const response1 = await Store.dispatch(`Company/get`, apiParams);
      if (!response1.hasError) {
        const { entries } = response1.data.contents;
        let ids = [];
        ids.push(...listIds);
        ids.push(...entries.map((item) => item.id));
        const response2 = await Store.dispatch(`Company/getCompanyByIds`, {
          ids,
        });
        if (!response2.hasError) {
          const { entries } = response2.data.contents;
          this.COMPANY_ITEM = [...entries];
          this.COMPANY_ITEM.unshift({
            id: null,
            name: "",
          });
        }
      }
    },

    /**
     * get data select 点検会社
     */
    async getListFieldInspector() {
      if (this.currentSite.field_id) {
        let apiParams = {
          field_id: this.currentSite.field_id,
        };
        const res = await Store.dispatch(
          `${STORE}/getListMachineInspector`,
          apiParams
        );
        if (!res.hasError) {
          const data = res.data.contents.entries;
          this.COMPANY_INSPECTOR = [];
          this.COMPANY_INSPECTOR = [...data];
          this.COMPANY_INSPECTOR.unshift({
            id: null,
            name: "",
          });
        }
      }
    },
    setEmptyDataRegister() {
      const _editedItem = _.cloneDeep(this.editedItem);
      _editedItem.machine_fields.forEach((item) => {
        item.machine_number = null;
        item.machine_field_name = null;
        item.serial_number = null;
      });
      this.editedItem = _editedItem;
    },

    convertName(name, length) {
      let result = "";
      if (name) {
        result = name;
        if (name.length > length) {
          result = name.substring(0, length) + "•••";
        }
      }
      return result;
    },

    openPrint() {
      this.popups.isShowPrintDialog = true;
    },

    closePrintDialog() {
      this.popups.isShowPrintDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    // print machine
    async print(type) {
      let params = {
        file_code: type,
        field_id: this.currentSite.field_id,
      };
      let URL_EXPORT_FILE = `${process.env.VUE_APP_API_BASE_URL}/reports/machine_fields_list`;
      axios({
        url: URL_EXPORT_FILE,
        method: "GET",
        params: params,
        timeout: 8000,
        interceptors: {
          request: axios.interceptors.request.use((config) => {
            const GRN = JSON.parse(sessionStorage.getItem("GRN"));
            sessionStorage.getItem("GRN") &&
              (config.headers.Authorization = `${GRN.Login.api_token}`);
            return config;
          }),
        },
      })
        .then((response) => {
          if (response.data.status_code == 200) {
            this.closePrintDialog();
            const { file, file_name } = response.data.contents;
            const byteCharacters = atob(file);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const url = window.URL.createObjectURL(new Blob([byteArray]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file_name);
            document.body.appendChild(link);
            link.click();
          } else {
            this.closePrintDialog();
            const error_status = response.data.status_code;
            let error_message = "";
            switch (true) {
              case /401/.test(error_status):
                error_message = "認証エラー";
                break;
              case /503/.test(error_status):
                error_message = "メンテナンス中です";
                break;
              default:
                error_message = "システムエラー";
            }
            Store.dispatch(
              "Error/show",
              {
                status: response.data.status,
                message: error_message,
              },
              { root: true }
            );
          }
        })
        .catch(() => {
          this.closePrintDialog();
          Store.dispatch(
            "Error/show",
            {
              status: 500,
              message: "システムエラー",
            },
            { root: true }
          );
        });
    },

    openDialogExport() {
      this.popups.isShowExportDialog = true;
    },
    closeExportDialog() {
      this.popups.isShowExportDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.selectedLeadApproved = [];
      });
    },
    async exportListQrCode(type) {
      var today = new Date();
      let dateYYMMDD = `${today.getFullYear()}${today.getMonth()}${today.getDay()}`;
      let apiParams = [];
      this.selectedItems.map(function (value) {
        apiParams.push(value.id);
      });
      const configParams = {
        url: DOWNLOAD_QR_CODE_URL,
        params: {
          machine_file_id: apiParams,
          file_code: type,
        },
      };
      await Store.dispatch(`File/download`, configParams).then((response) => {
        let typeFile = type === 1 ? "application/xlsx" : "application/pdf";
        let blob = new Blob([response.data], { type: typeFile });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        if (type === 1) {
          link.download = `Machine_Fields_Qr_${this.currentSite.field_id}${dateYYMMDD}.xlsx`;
        } else {
          link.download = `Machine_Fields_Qr_${this.currentSite.field_id}${dateYYMMDD}.pdf`;
        }
        link.click();
        this.closeExportDialog();
      });
    },
    clickToggle: function () {
      this.isActive = !this.isActive;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-data-table__wrapper table tbody tr td:nth-child(5) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15vw;
}
::v-deep .v-data-table__wrapper table tbody tr td:nth-child(7) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 13vw;
}
</style>
